import React, { Component } from "react";
import * as Message from '../shared/Message';
import * as _conciliacaoMatriculaService from "./../../service/painel-conciliacao-matricula-service.js";

export class PainelConciliacaoMatricula extends Component {
    static displayName = PainelConciliacaoMatricula.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            result: []
        };
    }

    async componentDidMount() {

        this.setState({ isLoading: true});
        var resultDados = await _conciliacaoMatriculaService.GetAll();
        this.setState({ isLoading: false });

        if (resultDados != null && resultDados != undefined) {
            if (resultDados.value != null && resultDados.value != undefined && resultDados.value.length > 0) {
                this.setState({ result: resultDados.value });
            } else {
                Message.ExibeMensagem("Nenhuma matrícula encontrada", 2);
            }

            Message.ExibeListMensagem(resultDados.messages);

        } else {
            Message.ExibeMensagem("houve problemas ao identificar as matriculas", 2);
        }
    }



    render() {
        const { result } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Painel de Conciliação de Matricula</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Painel de Conciliação de Matricula</h2>
                    <div className="panel-body p-25 mb-3">
                        <div className="col-md-12 col-lg-12">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Matricula</th>
                                        <th scope="col">Aluno</th>
                                        <th scope="col">Unidade</th>
                                        <th scope="col">Ano</th>
                                        <th scope="col">Ordem de Venda</th>
                                        <th scope="col">Ficha</th>
                                        <th scope="col">Transações</th>
                                        <th scope="col">Serviço</th>
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {result.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{item.matricula}</td>
                                                <td>{item.aluno}</td>
                                                <td>{item.unidade}</td>
                                                <td>{item.ano}</td>
                                                <td>{item.ordemVenda}</td>
                                                <td>{item.ficha}</td>
                                                <td>{item.financeiro}</td>
                                                <td>{item.servico}</td>
                                            </tr>
                                        );
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
