import React from 'react';
import SidebarAppMenu from './sidebarapp-menu';
import '../../styles/scss/sidebarapp.scss';

const SideBarApp = (props) => {
    return (
        <nav id="sidebar" className="sidebar-wrapper">
            {props.children}
            <div onMouseEnter={props.handleHover} onMouseLeave={props.handleHover}>
                <div className="sidebar-content">
                    <SidebarAppMenu itens={props.opcoes} className="sidebar-item"/>
                </div>
            </div>
        </nav>
    )
};

export default SideBarApp;