export function ValoresReparcelamentoServicos(idMatricula, request) {
    
    return fetch(`/api/Pagamento/ValoresReparcelamentoServicos/${idMatricula}/0/284360004`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}
export function ChangeCalculatorValues(idMatricula, request){

    return fetch(
        `/api/Pagamento/AlteraValoresReparcelamentoServicos/${idMatricula}/0`,
        {
            method: "POST",
            mode: "cors",
            cache: "default",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        }
    )
        .then((response) => response.json())
        .then((result) => {
            return result;
            },
            (error) => {
                alert("Erro ao salvar novo plano.");
                console.log(error);
            })
        .catch((error) => {
            alert("Erro ao salvar novo plano.");
            console.log(error.message);
        });
};