import React, { Component } from "react";
import * as _integracaoMatriculaService from "./../../service/integracaoMatricula-service.js";
import * as Message from '../shared/Message';
import { Table } from "react-bootstrap";
import { ModalIntegracao } from "./modalDetalheIntegracaoGeral.js";
import { GetIntegracaoListFilterByDates } from "./../../service/integracaoMatricula-service.js";
import * as formValidacao from "../../Helpers/form-validacao.js";

export class IntegracaoMatriculasGeral extends Component {
    LimiteDeRengeSelecionado = 7;
    nowProgressBar = 0;
    makeRequestReplay = false;


    constructor() {
        super();
        this.state = {
            isLoading: false,
            selecionarTodos: false,
            matriculas: [],
            dataInicio: null,
            dataFim: null,
            showModalIntegracao: false,
            matriculaSelecionada: {},
            errors: {}
        };
    }

    async buscar() {
        if (this.validateDates()) {
            this.setState({ isLoading: true });
            const result = await GetIntegracaoListFilterByDates(this.state.dataInicio, this.state.dataFim);
            this.setState({ matriculas: result?.integrationErrorsDetailsList ?? [], isLoading: false });
        }

    }

    validateDates = () => {
        let dataini = new Date(this.state.dataInicio);
        let datafim = new Date(this.state.dataFim);

        var timeDiff = Math.abs(datafim.getTime() - dataini.getTime());
        var rangeDate = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (datafim < dataini) {
            Message.ExibeMensagem('A data fim não pode ser menor do que a data de início', '1', false);
            return false;
        }

        if (rangeDate > this.LimiteDeRengeSelecionado) {
            Message.ExibeMensagem(`O range de datas não pode ser maior do que ${this.LimiteDeRengeSelecionado} dias`, '1', false);
            return false;
        }

        return true;
    }

    onSelectAll = (checked) => {
        this.setState({ selecionarTodos: checked });
        let matriculas = this.state.matriculas;

        matriculas.forEach(matricula => {
            matricula.selected = checked;
        });

        this.setState({ matriculas: matriculas });
    }

    onSelect = (matriculaObj, checked) => {
        let matriculas = this.state.matriculas;

        let matricula = this.state.matriculas.find(x => x.codigoMatriculaErp === matriculaObj.codigoMatriculaErp);
        matricula.selected = checked;

        var index = this.state.matriculas.findIndex(x => x.codigoMatriculaErp === matriculaObj.codigoMatriculaErp)
        matriculas[index] = matricula;

        if (!checked) {
            this.setState({ selecionarTodos: checked });
        }

        this.setState({ matriculas: matriculas });
    }

    handleCloseModal = (approved) => {
        if (!approved) {
            this.setState({ showModalIntegracao: false, matriculaSelecionada: {} });
            return;
        }

        this.setState({ showModalIntegracao: false, matriculaSelecionada: {} });
        this.componentDidMount();
    }

    handleOpenModal = (matricula) => {
        this.setState({ showModalIntegracao: true, matriculaSelecionada: matricula });
    }

    reenviar = async () => {
        let codMatriculas = [];

        this.state.matriculas.map(x => {
            if (x.selected) {
                codMatriculas.push(x.codigoMatriculaErp);
            }
        });

        this.setState({ isLoading: true });

        await this.sendRequest(codMatriculas);

        this.buscar();
    }

    async sendRequest(codMatriculas) {
        for (const value of codMatriculas) {
            const request = { IntegrationReplayItemsList: { CodigoMatriculaErp: value } };

            const result = await _integracaoMatriculaService.ReplayIntegrations(request);

            if (!result?.statusResult || result?.statusResult?.length == 0) {
                Message.ExibeMensagem('Replay de integrações finalizado com ERRO', '2', false);
            } else {
                Message.ExibeMensagem('Replay de integrações finalizado com SUCESSO', '0', false);
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Matrículas com falhas de integração</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else if (this.state.showModalIntegracao) {
            return (
                <ModalIntegracao show={this.state.showModalIntegracao} onClose={(e) => this.handleCloseModal(e)} matricula={this.state.matriculaSelecionada} />
            );
        } else {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Matrículas com falhas de integração</h2>
                    <div className="panel-body p-25">
                        <div className="col-md-12 col-lg-12">

                            <div className="row mb-5">
                                <div className="col-md-3" style={{ marginTop: -16 }}>
                                    <div className="form-group">
                                        <label>Data de Início</label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                formValidacao.cssTexto(
                                                    this.state.errors["dataInicio"],
                                                    this.state.data
                                                )
                                            }
                                            name="dataInicio"
                                            value={this.state.dataInicio}
                                            onChange={(e) => this.setState({ dataInicio: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3" style={{ marginTop: -16 }}>
                                    <div className="form-group">
                                        <label>Data de Fim</label>
                                        <input
                                            type="date"
                                            className={
                                                "form-control " +
                                                formValidacao.cssTexto(
                                                    this.state.errors["dataFim"],
                                                    this.state.data
                                                )
                                            }
                                            name="dataFim"
                                            value={this.state.dataFim}
                                            onChange={(e) => this.setState({ dataFim: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.buscar()}
                                    >
                                        Buscar
                                    </button>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <button
                                        className="btn btn-primary"
                                        style={{ marginRight: 5 }}
                                        onClick={() => this.reenviar()}
                                    >
                                        Reenviar
                                    </button>

                                </div>
                            </div>

                            <Table className="mt-2 tableStyle" bordered responsive style={{ backgroundColor: "#f5f5f5" }}>
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" checked={this.state.selecionarTodos} onClick={(event) => this.onSelectAll(event.target.checked)}></input></th>
                                        <th>Matricula CRM</th>
                                        <th>Matricula ERP</th>
                                        <th>Ano Letivo</th>
                                        <th>Código do Aluno</th>
                                        <th>Nome do Aluno</th>
                                        <th>Curso</th>
                                        <th>Turma</th>
                                        <th>Turno</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.matriculas?.map((matricula, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                style={
                                                    matricula?.selected == true
                                                        ? {
                                                            backgroundColor: "#cae9ef",
                                                            color: "#ffffff",
                                                            cursor: "pointer",
                                                        }
                                                        : { cursor: "pointer" }
                                                }

                                            >
                                                <td><input type="checkbox" checked={matricula?.selected ? true : false} onClick={(event) => { this.onSelect(matricula, event.target.checked) }} /></td>
                                                <td>{matricula?.codigoMatricula}</td>
                                                <td>{matricula?.codigoMatriculaErp}</td>
                                                <td>{matricula?.anoLetivo_Name}</td>
                                                <td>{matricula?.codigoCliente}</td>
                                                <td>{matricula?.estudante_Name}</td>
                                                <td>{matricula?.servico}</td>
                                                <td>{matricula?.turma_Name}</td>
                                                <td>{matricula?.periodo}</td>
                                                <td>{matricula?.status}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-info"
                                                        style={{ marginRight: 5 }}
                                                        onClick={() => this.handleOpenModal(matricula)}>
                                                        Detalhes
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
