import React, { Component } from "react";
import * as _integracaoMatriculaService from "./../../../service/integracaoMatricula-service.js";
import * as Message from '../../shared/Message';

export class ReplayIntegracaoParcial extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            matricula: null,
            finishedReplay: false
        };
    }

    enviar = async () => {

        this.setState({ isLoading: true });
        const result = await _integracaoMatriculaService.ReplayIntegracaoParcial(this.state.matricula);
        this.setState({ isLoading: false });

        if (result.statusCode == 0) {
            Message.ExibeListMensagem(result.messages, false);
        } else {
            Message.ExibeListMensagem(result.messages, false);
        }
    }

    render() {
        return (
            <div className="panel">
                <h2 class="tituloCss">Replay Integração Parcial</h2>
                <div className="panel-body p-25">
                    <div className="col-md-12 col-lg-12">
                        <div className="filtros">
                            <form onSubmit={(e) => this.Buscar(e)}>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Matricula CRM</label>
                                            <input
                                                type="input"
                                                className={"form-control"}
                                                name="input-matricula"
                                                value={this.state.matricula}
                                                onChange={(e) => this.setState({ matricula: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <input
                                            className="btn btn-primary"
                                            type="submit"
                                            disabled={this.state.isLoading ? "disabled" : ""}
                                            value={this.state.isLoading ? "Enviando..." : "Enviar"}
                                            style={{ marginTop: 15 }}
                                            onClick={() => this.enviar()}
                                        />
                                    </div>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
