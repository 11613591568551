import React, { Component } from "react";
import '../../styles/css/app.css';
import {
    PersonFill,
    PrinterFill,
    PlusCircle
} from 'react-bootstrap-icons';
import * as _faturamentoService from './../../service/faturamento-service';
import * as _responsavelService from './../../service/responsavel-service';

class Faturamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Matricula: this.props.Matricula,
            isLoading: false,
            responsaveis: [],
            controle: {
                index: -1,
                value: false
            }
        }
    }
    async componentDidMount() {
        this.listResponsaveis();
        this.getData();
    }
    async getData() {
        this.setState({ isLoading: true });
        var data = await _faturamentoService.GetFaturamento(this.state.Matricula.idMatricula);
        this.setState({ data: data.parcelas ?? [], isLoading: false });
    }
    async listResponsaveis() {
        this.setState({ isLoading: true });
        var responsaveis = await _responsavelService.ListResponsaveisByMatricula(this.state.Matricula.idMatricula);
        this.setState({ responsaveis: responsaveis ?? [], isLoading: false });
    }
    getResponsavelFinanceiro(responsaveis) {
        var responsavel = responsaveis.find(x => x.tipoResponsavel == "Financeiro");
        if (responsavel != null && responsavel != undefined) {
            return responsavel.codigoErp + ": " + responsavel.nome;
        }
        return "";
    }

    render() {
        const { data, isLoading, responsaveis } = this.state;
        if (isLoading) {
            return (<div><h4>Loading...</h4></div>);
        } else {
            return (
                <div className="row p-b-10">
                    <h3 className="NomeAlunoM p-t-10"><PersonFill className='fa-icon iconeAzul' />{this.getResponsavelFinanceiro(responsaveis)}</h3>
                    <div className="p-10">
                        <table className="table table-striped table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>Competência</th>
                                    <th>Número da NF</th>
                                    <th>Status</th>
                                    <th>Valor Total da Nota</th>
                                    <th>Responsável</th>
                                    <th>Danfe</th>
                                    <th>Detalhes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 && (
                                        data.map((parcela, index) => {
                                            return this.CarregarParcela(parcela, index)
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        
    }
    OnClick(e, index) {
        e.preventDefault();
        const { controle } = this.state;
        if (controle.index == index && controle.value == true) {
            this.setState({ controle: { index: index, value: false } })
        } else {
            this.setState({ controle: { index: index, value: true } })
        }

    }
    CarregarParcela(parcela, index) {
        return (
            <>
                <tr>
                    <td>{parcela.competencia}</td>
                    <td>{parcela.numeroNF}</td>
                    <td>{parcela.status}</td>
                    <td>{parcela.valorTotalNota}</td>
                    <td>{parcela.responsavel}</td>
                    <td><PrinterFill className='fa-icon iconeAzul' />{parcela.danfe}</td>
                    <td>
                        <a onClick={(e) => this.OnClick(e, index)}  class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#">
                            <PlusCircle className='fa-icon iconeAzul' />
                        </a>
                    </td>
                </tr>
                {this.CarregarDetalhes(parcela, index)}
            </>
        )
    }

    CarregarDetalhes(parcela, index) {
        const { controle } = this.state;
        return (
            <tr>
                <td colspan="7">
                    <div id="collapseFinanceiro1" className={controle.index == index && controle.value == true ? "panel-collapse" : "panel-collapse collapse"}>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Descrição</th>
                                    <th>Configuração</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    parcela.detalhes.length > 0 && (
                                        parcela.detalhes.map((detalhe, index) => {
                                            return (
                                                <tr>
                                                    <td>{detalhe.item}</td>
                                                    <td>{detalhe.descricao}</td>
                                                    <td>{detalhe.configuracao}</td>
                                                    <td>{detalhe.valor}</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr >
        )
    }

}
export default Faturamento;