import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import "./styles.css";

import {
    CaretUpSquare,
    CaretDownSquare,
    CaretUpFill,
    CaretDownFill,
} from 'react-bootstrap-icons';
import Version from "../Version/Version";

const SidebarAppMenu = (props) => {
    const [activeItem, setItem] = useState(-1);
    const [activeSubItem, setSubItem] = useState(-1);
    const [activeSubSubItem, setSubSubItem] = useState(-1);
    const { itens } = props;

    const SubMenu = (item, open) => {
        return (item.submenu && sessionStorage.getItem('@SECRETARIA/idUnidade') != "" &&
            <Collapse isOpen={open}>
                {item.submenu ? (<div className="sidebar-submenu" >
                    <ul>

                        {item.submenu.map((i, idx) => (
                            <li key={idx} className={`${idx === activeSubSubItem ? 'active' : ''}`} id='edi-sub-item-SUB'>
                                <LinkContainer to={i.link || {}}>
                                    <Button variant="link" onClick={() => setSubSubItem((idx === activeSubSubItem ? -1 : idx))}>
                                        <i className="menu-icon">{i.icon}</i>
                                        {i.title}
                                    </Button>
                                </LinkContainer>
                            </li>
                        ))
                        }

                    </ul>
                </div>) : ''}
            </Collapse>
        )
    }



    return (
        itens &&
        <div className={`sidebar-menu ${props.className}`}>
            <ul>
                {itens.map((item, index) => {
                    const selected = (index === activeItem);
                    return (
                        <li key={index} className={`sidebar-dropdown `}>
                            <a href={item.link} onClick={() => { setItem((index === activeItem ? -1 : index)) }}>
                                
                                {item.icon}
                                <span className="menu-text">{item.title}</span>
                                
                            </a>
                            
                            {/*<Collapse isOpen={index === activeItem}>*/}
                            <Collapse className="show">
                                {item.submenu && sessionStorage.getItem('@SECRETARIA/idUnidade') != "" ? (<div className="sidebar-submenu">
                                    <ul>
                                        {item.submenu.map((i, idx) => {
                                            if (i.show) {
                                                const hasSub = i['submenu'];
                                                let ret = (
                                                    //<li key={idx} className={`${idx === activeSubItem ? 'active' : ''}`}>
                                                    <li key={i.itemId}>
                                                        <LinkContainer to={(!hasSub && i.link) || {}}>
                                                            <Button variant="link" onClick={() => setSubItem((i.itemId === activeSubItem ? i.itemId : i.itemId))}>

                                                                {i.itemId === activeSubItem ? '>' : ''} {i.title}
                                                            </Button>
                                                        </LinkContainer>
                                                    </li>);

                                                if (hasSub) {
                                                    const selected = (idx === activeSubItem);
                                                    const sub = SubMenu(i, activeSubItem === idx);
                                                    ret = (<>
                                                        <li key={i.itemId} className={`sidebar-dropdown ${selected ? 'selected' : ''}`}>
                                                            <a href={item.link} onClick={() => { setSubItem((selected ? i.itemId : i.itemId)) }}>
                                                                {i.itemId === activeSubItem ? '>' : ''}{i.title}
                                                                <span className="caret">{selected ? <CaretUpFill /> : <CaretDownFill />}</span>
                                                            </a>
                                                            {sub}
                                                        </li>
                                                    </>);
                                                }
                                                return ret;
                                            }
                                            
                                        }
                                        )}
                                    </ul>
                                </div>) : ''}
                            </Collapse>
                        </li>
                    )
                })
                }
            </ul>
            
            <div className="sidebar-version-container">
                <Version/>
            </div>
        </div>
    )
};

export default SidebarAppMenu;
