import React from 'react';
import ReactTooltip from "react-tooltip";
import './styles.css';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

function Version() {
  const [timestamp, setTimestamp] = React.useState("");
  React.useMemo(async () => {
    fetch(`/meta.txt?t=${new Date().getTime()}`, {
      "cache": "no-cache"
    })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionTimestamp = meta.timestamp;
        if (latestVersionTimestamp) {
          const t = new Date(latestVersionTimestamp).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          });
          setTimestamp(t);
        }
      })
      .catch(console.error);
  }, [])
  
  return (
    <p 
      className="seb-version-container"
      data-tip={`Versão ${global.appVersion} - ${timestamp}`}
    >
      <ReactTooltip
        place="top"
        type="dark"
        effect="float"
        className="tooltip"
      />
      v{global.appVersion}
    </p>
  );
}

export default Version;