import React, { useState, useEffect } from "react";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, BrowserAuthError } from "@azure/msal-browser";
import { fetchDataGET } from "../fetch";

const AuthContext = React.createContext({
    listUnidades: [],
    setListUnidades: () => {},
    logoUnidade: "",
    setLogoUnidade: () => {},
  });

const AuthProvider = ({ children }) => {
  const scopes = ["user.read", "group.read.all"];
  const { result, error, login } = useMsalAuthentication(InteractionType.Popup, {scopes: scopes});
  const [graphData, setGraphData] = useState(null);
  const [listUnidades, setListUnidades] = useState([]);
  const [logoUnidade, setLogoUnidade] = useState(null);

  useEffect(() => {
      if (!!graphData) {
          return
      }
      if (!!error) {
          if (error instanceof BrowserAuthError) {
              login(InteractionType.Redirect, {
                  scopes: ["user.read", "group.read.all"]
              })
          }
          console.log(error);
      }

      if (result) {
          sessionStorage.setItem('@SECRETARIA/msalToken', result.accessToken);
          sessionStorage.setItem('@SECRETARIA/nomeUsuarioLogado', result.account.name);
          sessionStorage.setItem('@SECRETARIA/emailUsuarioLogado', result.account.username);

          const fetchGraphData = async () => {
              await fetchDataGET("https://graph.microsoft.com/v1.0/me", result.accessToken)
              .then(data => setGraphData(data))
              .catch(error => console.log(error));
          }

          const fetchMemberOf = async () => {
              await fetchDataGET("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true&$orderby=displayName&$select=id,displayName,description", result.accessToken)
              .then(data => {
                  let gruposSecretaria = data.value?.filter(grupos => grupos.displayName.toLowerCase().indexOf("secretaria") > -1);
                  let codigoUnidadeList = gruposSecretaria?.map(({description}) =>  description) 
                  sessionStorage.setItem('@SECRETARIA/unidadesPermitidas', JSON.stringify(codigoUnidadeList))
                  setListUnidades(codigoUnidadeList);
              }).catch(error => console.log(error));
          }

          fetchGraphData();
          fetchMemberOf();
      }
  }, [error, result, graphData, login]);
  
  return (
    <AuthContext.Provider value={{listUnidades, setListUnidades, logoUnidade, setLogoUnidade}}>
      {children}
    </AuthContext.Provider>
  )
}
export { AuthContext, AuthProvider };
