import React, { Component } from "react";
import '../../styles/css/app.css';
import * as _responsavelService from './../../service/responsavel-service';

import {
    PersonFill,
    CurrencyDollar,
    PeopleFill
} from 'react-bootstrap-icons';

class Responsaveis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsaveis: [],
            Matricula: this.props.Matricula,
            isLoading: false
        }
    }
    async componentDidMount() {
        this.listResponsaveis();
    }
    async listResponsaveis() {
        this.setState({ isLoading: true });
        var responsaveis = await _responsavelService.ListResponsaveisByMatricula(this.state.Matricula.idMatricula);
        this.setState({ responsaveis: responsaveis ?? [], isLoading:false });
    }
    getResponsavelFinanceiro(responsaveis) {
        var responsavel = responsaveis.find(x => x.tipoResponsavel == "Financeiro");
        if (responsavel != null && responsavel != undefined) {
            return responsavel.codigoErp + ": " + responsavel.nome;
        }
        return "";
    }

    render() {
        const { responsaveis, isLoading } = this.state;
        if (isLoading) {
            return (<div><h4>Loading... <img width="25" height="25" src="img/loading.gif" /></h4></div>);
        } else {
            return (
                <div className="row p-b-10">
                    {/*<h3 className="NomeAlunoM p-t-10"><PersonFill className='fa-icon iconeAzul' />*/}
                    {/*    {*/}
                    {/*        this.getResponsavelFinanceiro(responsaveis)*/}
                    {/*    }*/}
                    {/*</h3>*/}

                    <fieldset className='p-b-10 p-t-10'>
                        <legend className="pull-left width-full">Responsáveis <PeopleFill className='fa-icon iconeAzul' /> </legend>
                        <div className="row">
                            <div className="p-10">
                                <table className="table table-striped table-bordered text-center">
                                    <thead>
                                        <tr>
                                            <th>Conta (ERP)</th>
                                            <th>Nome</th>
                                            <th>Cpf</th>
                                            <th>Telefone (Celular)</th>
                                            <th>Telefone (Fixo)</th>
                                            <th>Telefone (Recado)</th>
                                            <th>E-mail</th>
                                            <th>Tipo de Responsável</th>
                                            <th>% de rateio</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            responsaveis.length > 0 && (
                                                responsaveis.map((responsavel, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{responsavel.codigoErp}</td>
                                                            <td>{responsavel.nome}</td>
                                                            <td>{responsavel.cpf}</td>
                                                            <td>{responsavel.celular}</td>
                                                            <td>{responsavel.telefoneFixo}</td>
                                                            <td></td>
                                                            <td>{responsavel.email}</td>
                                                            <td>{responsavel.tipoResponsavel}</td>
                                                            <td>{responsavel?.rateio}</td>
                                                            <td>{responsavel.urlContrato && (<a target='_blank' href={responsavel.urlContrato}>Contrato</a>)}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                    </fieldset>


                </div>
            );
        }
    }

}
export default Responsaveis;