import React, { Component } from "react";
import '../../styles/css/app.css';
import {
    FileEarmarkMedicalFill,
    HandbagFill,
    BriefcaseFill,
    Bank2,
    FileEarmarkMedical,
    ReceiptCutoff,
    PersonFill
} from 'react-bootstrap-icons';
import { Tabs, Tab, Modal, Row, Button, Col, Form, Card, Container } from "react-bootstrap";

import Profile from "./Matricula";
import Responsaveis from "./Responsaveis";
import Faturamento from "./Faturamento";
import Financeiro from "./Financeiro";

export default class Matriculas extends Component {
    static displayName = Matriculas.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            Matricula: this.props.Matricula,
            componenteSelecionado:"Matricula"
        }
    }
    async handleSelect(value) {
        this.setState({ componenteSelecionado: value });
    }

    CarregarComponenteFilho(tab) {
        switch (tab) {
            case "Matricula":
                return (
                    <Profile Matricula={this.state.Matricula} />
                );
                break;
            case "Responsaveis":
                return (
                    <Responsaveis Matricula={this.state.Matricula} />
                );
                break;
            case "Financeiro":
                return (
                    <Financeiro Matricula={this.state.Matricula} />
                );
                break;
            case "Faturamento":
                return (
                    <Faturamento Matricula={this.state.Matricula} />
                );
                break;
        }
    }

    render() {
        return (
            <div className="panel">
                
                <div className="panel-body p-25">
                    <div className="col-md-12 col-lg-12">

                <Tabs defaultActiveKey="Matricula" onSelect={(e) => this.handleSelect(e)}>
                        <Tab eventKey="Matricula" title="Matrícula">
                            {this.state.componenteSelecionado == "Matricula" && (this.CarregarComponenteFilho(this.state.componenteSelecionado))}
                        </Tab>
                        <Tab eventKey="Responsaveis" title="Responsáveis" >
                            {this.state.componenteSelecionado == "Responsaveis" && (this.CarregarComponenteFilho(this.state.componenteSelecionado))}
                        </Tab>
                        <Tab eventKey="Financeiro" title="Financeiro">
                            {this.state.componenteSelecionado == "Financeiro" && (this.CarregarComponenteFilho(this.state.componenteSelecionado))}
                        </Tab>                        
                        {/*<Tab eventKey="Faturamento" title="Faturamento">*/}
                        {/*    {this.state.componenteSelecionado == "Faturamento" && (this.CarregarComponenteFilho(this.state.componenteSelecionado))}*/}
                        {/*</Tab>*/}
                    </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}
