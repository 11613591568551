import React, { Component } from 'react';
import { ModalClean } from '../shared/ModalClean';
import { ModalBase } from '../shared/ModalBase';
import { Dropdown, Button, DropdownButton, Row } from "react-bootstrap";
import * as Message from '../shared/Message';
import * as _matriculaService from '../../service/matricula-service.js';
import * as _cancelamentoService from '../../service/cancelamento-service';
import * as _movimentacoesService from './../../service/movimentacoes-service.js';
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as _utils from "../../Utils.js";
import { PersonFill } from 'react-bootstrap-icons';

import {
    NavDropdown,
    Nav,
} from 'react-bootstrap';

export class Modal_CancelarMatricula extends Component {
    static displayName = Modal_CancelarMatricula.name;

    constructor(props) {
        super(props);
        this.state = {
            exibeModal: false,
            isLoadingConfirmacao: false,
            isLoadingCarregamento: false,
            isLoading: false,
            matriculaCancelada:false,
            errors: {},
            matricula: "",
            motivosCancelamento: [],
            motivoSelecionado: {},
            isencaoMultaSelecionado: "Solicitar isenção de multa?",
            isencaoDaMultaSolicitada: false,
            servicoPermiteIsencaoDaMulta: false,
            valorMulta: {},
            permiteMovimentarAvista: true
        };

        this.CarregarMotivosCancelamento = this.CarregarMotivosCancelamento.bind(this);


    }

    criarCancelamento() {

        if (!this.state.motivoSelecionado?.id) {
            Message.ExibeMensagem("Deve ser selecionado um motivo.", 1, true);
            return;
        }

        let solicitaIsencaoMulta = this.state.isencaoMultaSelecionado == "Sim" ? true : false;

        var request =
        {
            idMatricula: this.props.Matricula?.idMatricula,
            idMotivoCancelamento: this.state.motivoSelecionado?.id,
            isencaoDaMultaSolicitada: solicitaIsencaoMulta
        };

        this.setState({ isLoadingConfirmacao: true });

        _cancelamentoService.PostCriarCancelamento(request).then((result) => {
            if (result == undefined) {
                Message.ExibeMensagem("Erro ao processar cancelamento.", 1, true);
            } else {
                if (result.statusCode != 0) {
                    Message.ExibeListMensagem(result.messages, true, "");
                } else {
                    if (result.value?.length > 0) {
                        result.value.map((value) => {
                            if (_utils.ValidaBoleto(value)) {
                                _utils.DownloadArquivoPDF(value.boleto, "boleto_cancelamento");
                            }
                        });
                    }

                    if (
                        result.messages != null &&
                        result.messages != undefined &&
                        result.messages.length > 0 &&
                        result.messages[0].text == "Solicitado"
                    ) {
                        Message.ExibeMensagem("Uma solicitação de aprovação de isenção foi criada.Aguardando aprovação.", '0', true);
                    } else {
                        Message.ExibeListMensagem(result.messages, true, "");
                        this.setState({ matriculaCancelada: true });
                    }

                    this.setState({ isLoadingConfirmacao: false });
                    setTimeout(() => { this.props.Fechar() }, 10000);
                }
            }
        });
    }

    async CarregarMotivosCancelamento() {
        this.setState({ isLoadingCarregamento: true, motivoEditar: {} });
        var motivosCancelemento = await _matriculaService.ListMotivosCancelamento();

        if (motivosCancelemento != null) {
            this.setState({ motivosCancelamento: motivosCancelemento });
        }
        this.setState({ isLoadingCarregamento: false });
    }

    async componentDidMount() {

        this.setState({ isLoading: true });
        await _cancelamentoService.GetMultaCancelamento(this.props.Matricula.idMatricula).then((result) => {
           
            if (result == null || result == undefined) {
                Message.ExibeMensagem("Cálculo para multa não cadastrado.", 1, true);
            } else {

                let valorMulta = 0;
                result.valorMultaList.forEach((obj) => {
                    
                    valorMulta += obj.valor;
                })
                this.setState({
                    valorMulta: valorMulta,
                    servicoPermiteIsencaoDaMulta: result.servicoPermiteIsencaoDaMulta,
                    isencaoDaMultaSolicitada: result.isencaoDaMultaSolicitada
                });
            }
        });

        await _matriculaService.GetMatricula(this.props.Matricula?.idMatricula).then((result) => {
            if (result.razaoStatus_Value == '956840003') {
                this.setState({ matriculaCancelada: true });
            } else {
                this.CarregarMotivosCancelamento();
            }
        });

        var idUnidade = sessionStorage.getItem("@SECRETARIA/idUnidade");
        var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.props.Matricula.idMatricula, idUnidade, 284360007, 0);

        this.setState({ isLoading: false, permiteMovimentarAvista: permiteMovimentarAvista.value });
    }


    render() {

        {
            if (!this.state.permiteMovimentarAvista) {
                return (
                    <div >
                        <div className=" form-group d-flex justify-content-center">
                            <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                        </div>
                        <div className=" form-group d-flex justify-content-center">
                            <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                        </div>
                    </div>
                );
            }
        }

        {
            if (this.state.matriculaCancelada) {
                return (
                    <div >

                        <div className=" form-group d-flex justify-content-center">

                            <h3 style={{ color: '#0068b2' }}> <strong>MATRICULA  {this.props.Matricula?.codigoMatricula} CANCELADA </strong>
                            </h3>
                        </div>

                    </div>
                );
            }
        }

        if (this.state.isLoading) {
            return (<div className="form-group d-flex justify-content-center"><img width="50" height="50" src="img/loading.gif" /></div>);
        } else {
            return (
                <div className="row p-b-10">
                <fieldset className="p-b-10 p-t-10">
                  <legend className="pull-left width-full">
                    Informações da Matrícula <PersonFill className="fa-icon iconeAzul" />{" "}
                  </legend>
                  <div className="row">
                    <div className="col-md-4 p-b-10">
                      <div className="form-group">
                        <label>Matrícula</label>
                        <input
                          className="form-control"
                          disabled
                          value={this.props.Matricula?.codigoMatricula}
                        />
                      </div>
                    </div>
                    <div className="col-md-8 p-b-10">
                      <div className="form-group">
                        <label>Nome</label>
                        <input
                          className="form-control"
                          disabled
                          value={this.props.Matricula?.estudante_Name}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 p-b-10">
                      <div className="form-group">
                        <label>Valor da Multa</label>
                        <input
                          className="form-control"
                          disabled
                          value={
                            "R$ " + _utils.FormatarMoedaToLocale(this.state.valorMulta)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12 p-b-10">
                      <div className="form-group">
                        <h6 style={{ color: "#ff0000" }}>
                            {this.state.isencaoDaMultaSolicitada ?
                                "Atenção: Já existe uma solicitação de isenção de multa criada para essa matricula." :
                                "Atenção: Ao confirmar o cancelamento da matrícula, não será possível voltar o processo."
                            }
                        </h6>
                        <h6 style={{ color: "#ff0000" }}>
                            {!this.state.servicoPermiteIsencaoDaMulta &&
                                "Atenção: O serviço não permite isenção de multa."
                            }
                        </h6>
                      </div>
                    </div>
                    {this.state.isencaoDaMultaSolicitada == false &&
                    <div className="col-md-6 p-b-10">
                      <div className="form-group">
                        <Nav className="me-auto">
                          <NavDropdown
                            className={
                              "Fundounidade ddlFundoBranco form-control " +
                              formValidacao.cssComboBox(
                                this.state.errors["motivoCancelamento"],
                                this.state.motivoSelecionado
                              )
                            }
                            title={
                              this.state.motivoSelecionado?.descricao ||
                              (this.state.isLoadingCarregamento
                                ? "Carregando... "
                                : "Selecione o motivo do cancelamento")
                            }
                            id="collasible-nav-dropdown"
                            disabled={
                              this.state.isLoadingConfirmacao ||
                              this.state.isLoadingCarregamento
                            }
                          >
                            {this.state.motivosCancelamento?.map((motivo, idx) => (
                              <NavDropdown.Item
                                key={idx}
                                className="cssScroll"
                                eventKey={motivo.id}
                                onClick={(e) => this.setState({ motivoSelecionado: motivo })}
                                href="#"
                              >
                                {motivo.descricao}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        </Nav>
                      </div>
                    </div>
                    }
                    {
                    (this.state.isencaoDaMultaSolicitada == false && this.state.servicoPermiteIsencaoDaMulta == true) &&
                    <div className="col-md-6 p-b-10">
                      <div className="form-group">
                        <Nav className="me-auto">
                          <NavDropdown
                            className={
                              "Fundounidade ddlFundoBranco form-control " +
                              formValidacao.cssComboBox(
                                this.state.errors["isencaoMulta"],
                                this.state.isencaoMultaSelecionado
                              )
                            }
                            title={this.state.isencaoMultaSelecionado}
                            id="collasible-nav-dropdown"
                            disabled={
                              this.state.isLoadingConfirmacao ||
                              this.state.isLoadingCarregamento
                            }
                          >
                            <NavDropdown.Item
                                key={1}
                                className="cssScroll"
                                eventKey={1}
                                onClick={(e) => this.setState({ isencaoMultaSelecionado: "Sim" })}
                                href="#"
                              >
                                Sim
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                key={2}
                                className="cssScroll"
                                eventKey={2}
                                onClick={(e) => this.setState({ isencaoMultaSelecionado: "Não" })}
                                href="#"
                              >
                                Não
                              </NavDropdown.Item>
                          </NavDropdown>
                        </Nav>
                      </div>
                    </div>
                    }
                    {this.state.isencaoDaMultaSolicitada == false &&
                    <div className="col-md-6 p-b-10">
                      <div className="form-group">
                        <Button
                          size="lg"
                          name="Confirmar"
                          className="btn btn-primary"
                          disabled={
                            this.state.isencaoDaMultaSolicitada ||
                            this.state.isLoadingConfirmacao ||
                            this.state.isLoadingCarregamento ||
                            !this.state.motivoSelecionado?.id ||
                            (this.state.isencaoMultaSelecionado == "Solicitar isenção de multa?")
                          }
                          onClick={() => {
                              this.criarCancelamento();
                          }}
                          style={{ width: "100%" }}
                        >
                          {this.state.isLoadingConfirmacao ? "Cancelando…" : "Confirmar"}
                        </Button>
                      </div>
                    </div>
                    }
                  </div>
                  <hr />
                </fieldset>
              </div>
            );
        }

    }
}
