import React, { Component } from "react";
import { ModalBase } from "../shared/ModalBase";
import * as Message from "../shared/Message";
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as _matriculaService from "./../../service/matricula-service.js";
import {Button, Spinner, Table} from "react-bootstrap";
import Matriculas from "../matricula/Matriculas";

import {
    NavDropdown,
    Nav,
} from 'react-bootstrap';

export class MatriculasGeral extends Component {
    static displayName = MatriculasGeral.name;

    constructor(props) {
        super(props);
        this.state = {
            exibeModal: false,
            tituloModalSelecionado: "",
            isLoading: false,
            errors: {},
            componenteSelecionado: "",
            matriculas: [],
            matriculaSelecionada: {},
            camposBusca: {
                matricula: "",
                matriculaErp: "",
                anoLetivo: "",
                estudante_Name: "",
                codigoCliente: "",
            },
        };
    }

    async componentDidMount() {
     
        if (sessionStorage.getItem('@SECRETARIA/unidade') == undefined ||
            sessionStorage.getItem('@SECRETARIA/unidade') == '' ||
            sessionStorage.getItem('@SECRETARIA/unidade') == null) {

            Message.ExibeMensagem("Selecione a unidade", "1", true);
            setTimeout(() => {
                window.location = "/";
            }, "3000")
        }
    }

    OpenModal(idModalSelecionado, TituloModal) {
        if (
            this.state.matriculaSelecionada.codigoMatricula == null ||
            this.state.matriculaSelecionada.codigoMatricula == undefined
        ) {
            Message.ExibeMensagem("Selecione uma matricula!", "1", true);
            return false;
        }
        this.setState({
            exibeModal: true,
            componenteSelecionado: idModalSelecionado,
            tituloModalSelecionado: TituloModal,
        });
    }

    CloseModal() {
        this.setState({ exibeModal: false });
    }

    OnChange(event) {
        var campos = this.state.camposBusca;
        campos[event.target.name] = event.target.value;

        this.setState({ campos: campos });
    }

    async OnClickTR(matricula) {
        return new Promise((resolve, reject) => {
            var matriculas = this.state.matriculas;
            matriculas.map((m) => {
                if (m.codigoMatricula == matricula.codigoMatricula) {
                    m.selected = true;
                } else {
                    m.selected = false;
                }
                return m;
            });
            this.setState({ matriculaSelecionada: matricula, matriculas: matriculas }, () => resolve());
        });
    }

    ComboBoxAno() {
        var anos = [];
        var anoInicial = 2021;
        var anoFinal = new Date().getFullYear() + 1;

        for (var i = anoInicial; i <= anoFinal; i++) {
            anos.push(i);
        }
        return anos;
    }

    FormValidation() {
        let errors = {};
        let formIsValid = true;
        let inputs = this.state.camposBusca;
        if (
            !formValidacao.isValidTexto(inputs.matricula, 3) &&
            !formValidacao.isValidTexto(inputs.matriculaErp, 3) &&
            !formValidacao.isValidTexto(inputs.codigoCliente, 3) &&
            !formValidacao.isValidTexto(inputs.estudante_Name, 2) &&
            !formValidacao.isValidComboBox(inputs.anoLetivo)
        ) {
            if (!formValidacao.isValidTexto(inputs.matricula, 3)) {
                errors["matricula"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["matricula"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidTexto(inputs.matriculaErp, 3)) {
                errors["matriculaErp"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["matriculaCrm"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidTexto(inputs.codigoCliente, 3)) {
                errors["codigoCliente"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["codigoCliente"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidTexto(inputs.estudante_Name, 3)) {
                errors["estudante_Name"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["estudante_Name"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidComboBox(inputs.anoLetivo)) {
                errors["anoLetivo"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["anoLetivo"] = "";
                formIsValid = true;
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    CarregarComponenteFilho() {
        return <Matriculas Matricula={this.state.matriculaSelecionada} />
    }

  async Buscar(e) {
    e.preventDefault();
    if (!this.FormValidation()) {
      return false;
    }
    var request = {
      CodigoMatricula: this.state.camposBusca.matricula,
      CodigoMatriculaErp: this.state.camposBusca.matriculaErp,
      AnoLetivo: this.state.camposBusca.anoLetivo,
      Estudante_Name: this.state.camposBusca.estudante_Name,
      CodigoCliente: this.state.camposBusca.codigoCliente,
      IdUnidade: sessionStorage.getItem("@SECRETARIA/idUnidade"),
      TipoFiltro : "matriculas"
    };

        this.setState({ matriculas: [], isLoading: true });

        var matriculas = await _matriculaService.ListMatriculas(request);
        if (matriculas != null) {
            this.setState({ matriculas: matriculas, isLoading: false });
        } else {
            this.setState({ isLoading: false });
        }
    }

    SelecioneAno(obj) {
        var camposBusca = this.state.camposBusca;
        
        if (obj == "" || obj == "-") {
            camposBusca.anoLetivo = "";
        } else {
            camposBusca.anoLetivo = obj.toString();
        }

        this.setState({ camposBusca: camposBusca });
    }

    render() {
        return (
            <div className="panel">
                <h2 class="tituloCss">Matrículas</h2>
                <div className="panel-body p-25">
                    <div className="col-md-12 col-lg-12">
                
                        
                <div className="filtros">
                    <form onSubmit={(e) => this.Buscar(e)}>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Nome do Aluno</label>
                                            <input
                                                className={
                                                    "form-control " +
                                                    formValidacao.cssTexto(
                                                        this.state.errors["estudante_Name"],
                                                        this.state.camposBusca.estudante_Name,
                                                        3
                                                    )
                                                }
                                                name="estudante_Name"
                                                value={this.state.camposBusca.estudante_Name}
                                                onChange={(e) => this.OnChange(e)}
                                            />
                                        </div>
                                    </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Matricula CRM</label>
                                    <input
                                        className={
                                            "form-control " +
                                            formValidacao.cssTexto(
                                                this.state.errors["matricula"],
                                                this.state.camposBusca.matricula,
                                                3
                                            )
                                        }
                                        name="matricula"
                                        value={this.state.camposBusca.matricula}
                                        onChange={(e) => this.OnChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Matricula ERP</label>
                                    <input
                                        className={
                                            "form-control " +
                                            formValidacao.cssTexto(
                                                this.state.errors["matriculaErp"],
                                                this.state.camposBusca?.matriculaErp,
                                                3
                                            )
                                        }
                                        name="matriculaErp"
                                        value={this.state.camposBusca?.matriculaErp}
                                        onChange={(e) => this.OnChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                            <label>Ano Letivo</label>
                                            <Nav className='me-auto'>
                                                <NavDropdown className={"ddlFundoBrancoSemBorda form-control " +
                                                    formValidacao.cssComboBox(
                                                        this.state.errors["anoLetivo"],
                                                        this.state.camposBusca.anoLetivo
                                                    )} value={this.state.camposBusca.anoLetivo} title={(this.state.camposBusca.anoLetivo != null && this.state.camposBusca.anoLetivo != undefined && this.state.camposBusca.anoLetivo != "" ? this.state.camposBusca.anoLetivo : "Selecione o ano...")} id='collasible-nav-dropdown'>

                                                    <NavDropdown.Item key={-1} onClick={() => this.SelecioneAno(0)} className="cssScroll dropDownItemSemBorda" >
                                                        {"-"}
                                                    </NavDropdown.Item>
                                                    {this.ComboBoxAno()?.map((ano, idx) => (

                                                        <NavDropdown.Item key={idx} onClick={() => this.SelecioneAno(ano)} className="cssScroll dropDownItemSemBorda" >
                                                            {ano}
                                                        </NavDropdown.Item>
                                                    ))}
                                                </NavDropdown>
                                            </Nav>
                                 
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Código do Aluno</label>
                                    <input
                                        className={
                                            "form-control " +
                                            formValidacao.cssTexto(
                                                this.state.errors["codigoCliente"],
                                                this.state.camposBusca.codigoCliente,
                                                3
                                            )
                                        }
                                        name="codigoCliente"
                                        value={this.state.camposBusca.codigoCliente}
                                        onChange={(e) => this.OnChange(e)}
                                    />
                                </div>
                                    </div>

                                    <div className="col-md-1 mb-3" >
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            disabled={this.state.isLoading}
                                            style={{ marginTop: 15 }}
                                        >
                                            {this.state.isLoading ? (
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    size="sm"
                                                >
                                                    <span className="visually-hidden">Carregando...</span>
                                                </Spinner>
                                            ) : "Buscar"}
                                        </Button>
                                    </div>
                            
                          </div>
                    </form>
                </div>
                <Table className="tableStyle" bordered responsive style={{ backgroundColor: "#f5f5f5" }}>
                    <thead>
                        <tr>
                            <th>Matricula CRM</th>
                            <th>Matricula ERP</th>
                            <th>Ano Letivo</th>
                            <th>Código do Aluno</th>
                            <th>Nome do Aluno</th>
                            <th>Curso</th>
                            <th>Turma</th>
                            <th>Turno</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.matriculas.map((matricula, index) => {
                            return (
                                <tr
                                    key={index}
                                >
                                    <td>{matricula.codigoMatricula}</td>
                                    <td>{matricula.codigoMatriculaErp}</td>
                                    <td>{matricula.anoLetivo_Name}</td>
                                    <td>{matricula.codigoCliente}</td>
                                    <td>{matricula.estudante_Name}</td>
                                    <td>{matricula?.servico}</td>
                                    <td>{matricula.turma_Name}</td>
                                    <td>{matricula?.periodo}</td>
                                    <td>{matricula?.status}</td>
                                    <td>
                                        <button
                                            className="btn btn-light"
                                            style={{ marginRight: 5 }}
                                            onClick={() => {
                                                this.OnClickTR(matricula)
                                                    .then(() => this.OpenModal(0, "Visão Geral"));
                                            }}
                                        >
                                            Visão Geral
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                <ModalBase
                    style={"max-Width: 850"}
                    show={
                        this.state.exibeModal &&
                        this.state.componenteSelecionado !== 4
                    }
                    Fechar={() => this.CloseModal()}
                    titulo={this.state.tituloModalSelecionado}
                >
                    {this.CarregarComponenteFilho()}
                </ModalBase>
                    </div>
                </div>
            </div>
        );
    }
}
