import React, { Component } from 'react';
import * as _matriculaService from "./../../service/matricula-service.js";
import * as _movimentacoesService from './../../service/movimentacoes-service.js';
import * as _turmaService from "./../../service/turma-service.js";
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as Message from "../shared/Message";

import {
    Container,
    NavDropdown,
    Nav,
    Navbar,
    Badge,
    Image,
} from 'react-bootstrap';

export class Movimentacoes_turma extends Component {
    static displayName = Movimentacoes_turma.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingBtn:false,
            errors: {},
            Matricula: this.props.Matricula,
            DadosMatricula: {},
            Turmas: [],
            DadosNovaTurma: {
                novaTurma: "",
                periodo: this.props?.Matricula?.periodo
            },
            nomeTurma: "",
            permiteMovimentarAvista: true
        };
    }
    async componentDidMount() {
        this.setState({ isLoading: true });
        var result = await _matriculaService.GetMatricula(this.state.Matricula.idMatricula);
        var dadosMatricula = {};
        var turmas = {};
        if (result != null && result != undefined) {
            dadosMatricula = result;
            var resultTurmas = await _turmaService.TurmasServicoUnidade(dadosMatricula.servico_Id);
            if (resultTurmas != null && resultTurmas != undefined) {
                turmas = (resultTurmas || []).filter(t => t.id !== dadosMatricula.turma_Id)
            }
        }
        var idUnidade = sessionStorage.getItem("@SECRETARIA/idUnidade");
        var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, idUnidade, 284360003, 0);

        this.setState({ isLoading: false, DadosMatricula: dadosMatricula, Turmas: turmas, permiteMovimentarAvista: permiteMovimentarAvista.value});
    }
    FormValidation() {
        let errors = {};
        let formIsValid = true;
        let inputs = this.state.DadosNovaTurma;

        if (!formValidacao.isValidComboBox(inputs.novaTurma)) {
            errors["novaTurma"] = "Campo obrigatório.";
            formIsValid = false;
        } else {
            errors["novaTurma"] = "";
            formIsValid = true;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    OnChange(event) {

        var campos = this.state.DadosNovaTurma;
        campos[event.target.name] = event.target.value;

        this.setState({ DadosNovaTurma: campos });
    }
    async Salvar(event) {
        try {
            event.preventDefault();
            if (!this.FormValidation()) {
                return false;
            }
            this.setState({ isLoadingBtn: true });
            var result = await _turmaService.AtualizarTurma(this.state.Matricula.idMatricula, this.state.DadosNovaTurma.novaTurma);
            debugger
            if (result != null && result != undefined && result.id != undefined && result.id != null) {
                var dadosMatricula = this.state.DadosMatricula;
                var dadosNovaTurma = this.state.DadosNovaTurma;
                var turma = this.state.Turmas.find(x => x.id == this.state.DadosNovaTurma.novaTurma);
                dadosMatricula.turma_Name = turma.nome;
                dadosMatricula.turma_Id = turma.id;
                dadosNovaTurma.periodo = turma.periodo;
                this.setState({ DadosMatricula: dadosMatricula, DadosNovaTurma: dadosNovaTurma });
                Message.ExibeMensagem("Turma atualizada com sucesso", "0", true);
            } else {
                Message.ExibeMensagem(result, "2", true);
            }
            this.setState({ isLoadingBtn: false });
        } catch (e) {
            Message.ExibeMensagem(e.message, "2", true);
            this.setState({ isLoadingBtn: false });
        }
    }

    SelecioneTurma(novaTurma) {

        var campos = this.state.DadosNovaTurma;
        campos.novaTurma = novaTurma.id;

        this.setState({ DadosNovaTurma: campos });
        this.setState({ nomeTurma: novaTurma.nome + " - Período: " + novaTurma.periodo });
    }

    render() {
        const { isLoading, Matricula, DadosMatricula, Turmas, DadosNovaTurma, isLoadingBtn, nomeTurma, permiteMovimentarAvista } = this.state;

        {
            if (!permiteMovimentarAvista) {
                return (
                    <div >
                        <div className=" form-group d-flex justify-content-center">
                            <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                        </div>
                        <div className=" form-group d-flex justify-content-center">
                            <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                        </div>
                    </div>
                );
            }
        }

        if (isLoading) {
            return (<div><h2>Loading...</h2></div>);
        } else {
            return (
                <div>
                    <div className="step-1">
                        <h1 className="modal-title">Aluno(a): {Matricula?.estudante_Name}</h1>
                        <h4>Turma atual: <b>{DadosMatricula?.turma_Name}</b>. <br /> Período: <b>{DadosNovaTurma?.periodo}</b>.</h4>
                    </div>
                    <form onSubmit={(e) => this.Salvar(e)}>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Turmas Disponíveis</label>
                                    <Nav className='me-auto'>
                                        <NavDropdown className={"Fundounidade ddlFundoBranco form-control " +
                                            formValidacao.cssComboBox(
                                                this.state.errors["novaTurma"],
                                                DadosNovaTurma?.novaTurma
                                            )}
                                            title={(nomeTurma != null && nomeTurma != undefined && nomeTurma != "" ? nomeTurma : "Selecione a turma...")} id='collasible-nav-dropdown'>
                                            {Turmas?.map((turma, idx) => (

                                                <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneTurma(turma)} href='#'>
                                                    {turma.nome + " - Período: " + turma.periodo}
                                                </NavDropdown.Item>
                                            ))}
                                            {(!Turmas || Turmas.length === 0) && (
                                              <NavDropdown.Item className="cssScroll" href='#'>
                                                  Nenhuma turma disponível
                                              </NavDropdown.Item>
                                            )}
                                        </NavDropdown>
                                    </Nav>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <button type="submit" className="btn btn-primary mt-2" disabled={isLoadingBtn ? "disabled" : ""}>{isLoadingBtn ? "Salvando..." : "Salvar"}</button>
                            </div>
                        </div>

                    </form>
                </div>
            );
        }
    }
}
