import React, {useContext, useState, useEffect} from 'react';
import { Container } from 'react-bootstrap';
import { HearderApp } from './HeaderApp';
import SideBar from './SideBar';
import MenuItens from '../Helpers/MenuItensApp';
import '../styles/css/app.css';
import * as _credencialIntegracaoService from "./../service/credential-integracao-service.js";
import {AuthContext} from '../context/AuthContext';

const menuReplayIntegracao = 4;

const itemMenuIntegracoes = 41;
const menuReplayTotal = 42;
const itemMenuContrato = 43;
const itemMenuReplayParcialRegular = 44;
const itemMenuReplayTotalUpSelling = 45;
const itemMenuReplayUpSellingParcial = 46;
const itemMenuReplayRegularParcial = 47;

const Layout = ({ children }) => {
    const { listUnidades } = useContext(AuthContext);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [opcoesMenu, setOpcoesMenu] = useState([]);

    useEffect(() => {
        var menuOpcoes = MenuItens;

        var email = sessionStorage.getItem('@SECRETARIA/emailUsuarioLogado');

        var permiteMovimentar = false;

        if(email != null && email != ""){

            const fetchCredencial = async () => {
                await _credencialIntegracaoService.PermiteIntegracao(email?.toLowerCase())
                .then(result => {
                    if (result?.permiteAcessarIntegracao) {
                        permiteMovimentar = true;
                        menuOpcoes.map(x => {
                            if (x.itemId == menuReplayIntegracao) {
                                x.show = true;
            
                            }
                            if (x.submenu) {
                                var submenu = x.submenu.map(y => {
                                    if (y.itemId == menuReplayTotal ||
                                        y.itemId == itemMenuContrato ||
                                        y.itemId == itemMenuIntegracoes ||
                                        y.itemId == itemMenuReplayParcialRegular ||
                                        y.itemId == itemMenuReplayTotalUpSelling ||
                                        y.itemId == itemMenuReplayUpSellingParcial ||
                                        y.itemId == itemMenuReplayRegularParcial) {
            
                                        y.show = true;
                                    }
                                    return y;
                                });
                                x.submenu = submenu;
                            }
            
                        })
                    }
                })
                .catch(error => console.log(error));
            }

            fetchCredencial();
        }

        sessionStorage.setItem('@SECRETARIA/permiteMovimentar', JSON.stringify(permiteMovimentar));
        var menuOpcoes = menuOpcoes.filter((x) => { return x.show == true; });

        setOpcoesMenu(menuOpcoes);
    },[listUnidades])

    return (
        <div>

            <div className='AjustePagina'>

                <SideBar opcoes={opcoesMenu} SideBarStatus={setSidebarOpen} />

                <Container fluid className={sidebarOpen ? 'side_closed' : 'side_opened'}>
                    <HearderApp />
                    {children}
                </Container>
            </div>
        </div>
    );
}

export { Layout };