export function ReplayIntegrations(request) {
    return fetch("api/IntegracaoMatricula/IntegrationErrors", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao reenviar integrações com erros.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao reenviar integrações com erros.");
            console.log(error.message);
        });
}

export function GetIntegrationLogList() {
    return fetch("api/IntegracaoMatricula/IntegrationErrors", {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar erros de integração.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar erros de integração.");
            console.log(error.message);
        });
}

export function GetIntegracaoListFilterByDates(startDate, endDate) {
    return fetch(`api/IntegracaoMatricula/IntegrationErro/List/${startDate}/${endDate}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar erros de integração.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar erros de integração.");
            console.log(error.message);
    });
}

export function GetIntegracaoTotalList(data) {
    return fetch(`api/IntegracaoMatricula/IntegracaoTotal/${data}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar erros de integração.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar erros de integração.");
            console.log(error.message);
        });
}

export function Replay(matricula) {
    var idMatricula = !matricula?.idMatriculaPai ? matricula.id : matricula?.idMatriculaPai;
    var idUpselling = 0;
    var ajusteJson = matricula?.checked ? true : false;
    return fetch(`api/IntegracaoMatricula/replay/${matricula.id}/${idUpselling}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao reenviar integrações com erros.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao reenviar integrações com erros.");
            console.log(error.message);
        });
}

export function Contrato(request) {
    return fetch("api/IntegracaoMatricula/contrato", {
        method: "Post",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    }).then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar contrato");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar contrato");
            console.log(error.message);
        });
}

export function ReplayIntegracaoParcial(request) {
    return fetch("api/IntegracaoMatricula/IntegrationParcial", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
        .catch((error) => {
            return error;
        });
}