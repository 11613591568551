export function AtualizarResponsavelMovimentacao(idMatricula, responsavel) {

    return fetch("api/MovimentacoesResponsavel/" + idMatricula, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(responsavel)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}

export function AtualizarResponsavelPedagogicoMovimentacao(idMatricula, responsavel) {

    return fetch("api/MovimentacoesResponsavel/pedagogico/" + idMatricula, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(responsavel)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}

export function GetbyIdResponsavel(idResponsavel) {

    return fetch("api/MovimentacoesResponsavel/GetResponsavel/" + idResponsavel, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar responsavel.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar responsavel.");
            console.log(error.message);
            return false;
        });
}
export function GetResponsavelByMatricula(idMatricula) {

    return fetch("api/MovimentacoesResponsavel/GetResponsavelByMatricula/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar responsavel.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar responsavel.");
            console.log(error.message);
            return false;
        });
}
export function GetResponsavelPedagogicoByMatricula(idMatricula) {

    return fetch("api/MovimentacoesResponsavel/GetResponsavelPedagogicoByMatricula/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar responsavel.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar responsavel.");
            console.log(error.message);
            return false;
        });
}
export function GetbyCpfResponsavel(cpf) {

    return fetch("api/MovimentacoesResponsavel/GetByCpf/" + cpf, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar responsavel.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar responsavel.");
            console.log(error.message);
            return false;
        });
}
export function ListResponsaveisByMatricula(idMatricula) {

    return fetch("api/MatriculaGeral/ListResponsaveisByMatricula/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao listar responsaveis.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao listar responsaveis.");
            console.log(error.message);
            return false;
        });
}
export function ListResponsaveisMatriculaByCodigoErp(idMatricula) {

    return fetch("api/MovimentacoesResponsavel/GetByCodigoErp/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao listar responsaveis.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao listar responsaveis.");
            console.log(error.message);
            return false;
        });
}
export function InserirResponsavelMovimentacao(idMatricula, responsavel) {

    return fetch("api/MovimentacoesResponsavel/InserirResponsaveisErp/" + idMatricula, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(responsavel)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}

export function FinalizarRateio(idMatricula, responsaveis) {

    return fetch("api/MovimentacoesResponsavel/FinalizarRateio/" + idMatricula, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(responsaveis)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}
