export function GetAll(matricula) {

    return fetch("api/PainelConciliacaoMatricula/GetAll", {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
        .catch(error => {
            return error;
        });
}