
export function GetByIdMatriculaMovimentacao(idMatricula, tipoMovimentacao) {
    return fetch("api/Movimentacoes/GetByIdMatriculaMovimentacao/" + idMatricula + "/" + tipoMovimentacao, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function GetBolsaAtual(idMatricula) {
    return fetch("api/Movimentacoes/bolsas/" + idMatricula, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
        .then((result) => {
            return result.value;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function SolicitaAltercaoBolsaAtual(request) {

    return fetch("api/Movimentacoes/bolsas", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}

export function AplicarAltercaoBolsaAtual(request) {

    return fetch("api/Movimentacoes/bolsas/aplicar", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}

export function GetListaTipoBolsa() {
    return fetch("api/Movimentacoes/bolsas/listaTipoBolsa", {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
        .then((result) => {
            // elimina bolsa irmão
            return (result.value || []).filter(r => r.value !== 284360004);
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}
export function GetAllTemplateMovimentacaoAtivos(idUnidade, permiteMovimentarTodos) {
    return fetch("api/Movimentacoes/GetAllTemplateMovimentacaoAtivos/" + idUnidade + "/" + permiteMovimentarTodos, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function PermiteMovimentarAvista(idMatricula, idUnidade, tipoMovimentacao, tipoMovResponsavel) {
    return fetch("api/Movimentacoes/PermiteMovimentarAvista/" + idMatricula + "/" + idUnidade + "/" + tipoMovimentacao + "/" + tipoMovResponsavel, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}
