import React, { Component } from "react";
import { Button, Collapse, Card, CardGroup, ListGroup } from "react-bootstrap";


import {
    NavDropdown,
    Nav,
} from 'react-bootstrap';

export class PagamentoAVista extends Component {
    static displayName = PagamentoAVista.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            result:[],
            base64: {},
            selectedFile: null,
        };
    }

    async componentDidMount() {

      
    }


    handleChange = (e) => {
        this.setState({ result: [] })
        this.setState({ ...this.state, selectedFile: e.target.files[0] });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ result: [] })
        const formData = new FormData();
        
        formData.append(
            'Dados',
            this.state.selectedFile
        );

        await fetch("api/PagamentoVistaCarga/Pagar", {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "multipart/form-data",
            },
            body: formData,
        })
            .then((res) => {
                return res.json();
            })
            .then(
                (result) => {
                    this.setState({ result: result })
                   
                    return result;
                },
                (error) => {
                    alert("Erro ao reenviar integrações com erros.");
                    console.log(error);
                }
            )
            .catch((error) => {
                alert("Erro ao reenviar integrações com erros.");
                console.log(error.message);
            });
    };

    render() {
        const { base64 } = this.state;
        return (
            <div className="panel">
                <h2 class="tituloCss">Carga Pagamento Cartão Crédito</h2>
                <div className="panel-body p-25 mb-3">
                    <div className="col-md-12 col-lg-12">

                        <form onSubmit={this.handleSubmit} asp-controller="PagamentoVistaCarga" asp-action="Pagar" encType="multipart/form-data">
                            <p>
                                <strong>Upload a .xlsx file:</strong>
                            </p>
                            <input className="mb-3" onChange={this.handleChange} type="file" />
                            <button className="btn primary mb-3" type="submit">Upload</button>
                        </form>
                    </div>
                </div>
                {this.state.result != null && this.state.result != undefined && this.state.result.length > 0 &&
                    <div className="mb-3">
                       
                    <table id="table-to-xls" className="table">
                        <tr className="row">
                            <th className="col-md-2">Matricula Erp</th>
                            <th className="col-md-2">Cpf</th>
                            <th className="col-md-2">Nsu</th>
                            <th className="col-md-2">PaymentId</th>
                            <th className="col-md-2">Status</th>
                            <th className="col-md-2">Menssagem</th>
                            </tr>
                            {this.state.result != null && this.state.result != undefined && this.state.result.map((item, index) =>
                                <tr className="row">
                                    <td className="col-md-2">{item.matriculaErp}</td>
                                    <td className="col-md-2">{item.cpfResponsavel}</td>
                                    <td className="col-md-2">{item.nsu}</td>
                                    <td className="col-md-2">{item.payment_id}</td>
                                    <td className="col-md-2">{item.status}</td>
                                    <td className="col-md-2">{item.mensagem}</td>
                                </tr>
                            )}
                        </table>
                    </div>
                }
         
            </div>
        );
    }
}
