export function PermiteIntegracao(email) {
    return fetch(`api/CredencialIntegracao/GetPermiteIntegracao/${email}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro na request de get de permissão de usuario ao menu de integrações.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro na request de get de permissão de usuario ao menu de integrações.");
            console.log(error.message);
        });
}