import React, { Component } from "react";
import '../../styles/css/app.css';
import {
    PersonFill,
    CurrencyDollar,
    PlusCircle
} from 'react-bootstrap-icons';
import * as _financeiroService from './../../service/financeiro-service';
import * as _responsavelService from './../../service/responsavel-service';
import * as _metodopagamentoService from '../../service/metodopagamento-service';
import * as _util from './../../Helpers/utils'

class Financeiro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Matricula: this.props.Matricula,
            dataAreaId: sessionStorage.getItem("@SECRETARIA/dataAreaId"),
            isLoading: false,
            responsaveis: [],
            controle: {
                index: -1,
                value: false
            }
        }
    }
    async componentDidMount() {
        this.setState({ isLoading: true });
        await this.listResponsaveis();
        await this.getData();
        this.setState({ isLoading: false });
    }
    async getData() {

        var data = await _financeiroService.GetFinanceiro(this.state.Matricula.idMatricula);
        console.log(data);
        this.setState({ data: data.parcelas ?? [] });
    }
    async listResponsaveis() {
        var responsaveis = await _responsavelService.ListResponsaveisByMatricula(this.state.Matricula.idMatricula);
        this.setState({ responsaveis: responsaveis ?? [] });
    }
    getResponsavelFinanceiro(responsaveis) {

        var responsavel = responsaveis.find(x => x.tipoResponsavel == "Financeiro");
        if (responsavel != null && responsavel != undefined) {
            return responsavel.codigoErp + ": " + responsavel.nome;
        }

        return "";
    }

    render() {
        const { data, isLoading, responsaveis } = this.state;
        if (isLoading) {
            return (<div><h4>Loading... <img width="25" height="25" src="img/loading.gif" /></h4></div>);
        } else {
            return (
                <div className="row p-b-10">
                    <legend className="pull-left width-full">Parcelas <CurrencyDollar className='fa-icon iconeAzul' /> </legend>
                    <div className="p-10">
                        <table className="table table-striped table-bordered text-center">
                            <thead>
                                <tr>
                                    <th>Código Responsável</th>
                                    <th>Nome Responsável</th>
                                    <th>Número da Fatura</th>
                                    <th>Data de vencimento</th>
                                    <th>Vencido</th>
                                    <th>Valor Original</th>
                                    <th>Valor a pagar</th>
                                    <th>Valor Pago</th>
                                    <th>Data de Pagamento</th>
                                    <th>Detalhes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 && (
                                        data.map((parcela, index) => {
                                            return this.CarregarParcela(parcela, index)
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
    }
    OnClick(e, index) {
        e.preventDefault();
        const { controle } = this.state;
        if (controle.index == index && controle.value == true) {
            this.setState({ controle: { index: index, value: false } })
        } else {
            this.setState({ controle: { index: index, value: true } })
        }

    }
    CarregarParcela(parcela, index) {
        return (
            <>
                <tr>
                    <td>{parcela.responsavel}</td>
                    <td>{parcela.nomeResponsavel}</td>
                    <td>{parcela.numeroFatura}</td>
                    <td>{parcela.datavencimento}</td>
                    <td>{parcela.vencido}</td>
                    <td>{_util.FormatarMoedaToLocale(parcela.valorOriginal)}</td>
                    <td>{_util.FormatarMoedaToLocale(parcela.valorPagar)}</td>
                    <td>{parcela?.isAcordo ? "acordo" : _util.FormatarMoedaToLocale(parcela.valorPago)}</td>
                    <td>{parcela.dataPagamento == "01/01/1900" ? "" : parcela.dataPagamento}</td>
                    <td>
                        <a href="#" onClick={(e) => this.OnClick(e, index)} class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                            <PlusCircle className='fa-icon iconeAzul' />
                        </a>
                    </td>
                </tr>
                {this.CarregarDetalhes(parcela, index)}
            </>
        )
    }

    CarregarDetalhes(parcela, index) {
        const { controle } = this.state;
        if (!parcela.isAcordo) {
            return (
                <tr>
                    <td colspan="10">
                        <div id="collapseFinanceiro1" className={controle.index == index && controle.value == true ? "panel-collapse" : "panel-collapse collapse"}>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Número da Fatura</th>
                                        <th>Item</th>
                                        <th>Descrição</th>
                                        <th>Configuração</th>
                                        <th>Método de Pagamento</th>
                                        <th>Data de Transação Valor</th>
                                        <th>Valor</th>
                                        <th>Desconto (Bolsa)</th>
                                        <th>Desconto (Método)</th>
                                        <th>Desconto (Abatimento da Reserva)</th>
                                        <th>Valor Total a Pagar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        parcela.detalhes.length > 0 && (
                                            parcela.detalhes.map((detalhe, index) => {
                                                return (
                                                    <tr>
                                                        <th>{detalhe.faturaId}</th>
                                                        <td>{detalhe.item}</td>
                                                        <td>{detalhe.descricao}</td>
                                                        <td>{detalhe.configuracao}</td>
                                                        <td>{detalhe.metodoPagamento}</td>
                                                        <td>{detalhe.dataTransacao}</td>
                                                        <td>{_util.FormatarMoedaToLocale(detalhe.valor)}</td>
                                                        <td>{_util.FormatarMoedaToLocale(detalhe.descontoBolsa)}</td>
                                                        <td>{_util.FormatarMoedaToLocale(detalhe.descontoMetodo)}</td>
                                                        <td>{_util.FormatarMoedaToLocale(detalhe.descontoReserva)}</td>
                                                        <td>{_util.FormatarMoedaToLocale(detalhe.valorTotalPagar)}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                </tbody>
                            </table>

                        </div>
                    </td>
                </tr >
                )
        } else {
            return (
                <tr>
                    <td colspan="10">
                        <div id="" className={controle.index == index && controle.value == true ? "panel-collapse" : "panel-collapse collapse"}>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Código Responsável</th>
                                        <th>Nome Responsável</th>
                                        <th>Número da Fatura</th>
                                        <th>Método de Pagamento</th>
                                        <th>Data de vencimento</th>
                                        <th>Vencido</th>
                                        <th>Valor Original</th>
                                        <th>Valor a pagar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{parcela.responsavel}</td>
                                        <td>{parcela.nomeResponsavel}</td>
                                        <td>{parcela.numeroFatura}</td>
                                        <td>{parcela.metodPagamento}</td>
                                        <td>{parcela.datavencimento}</td>
                                        <td>{parcela.vencido}</td>
                                        <td>{_util.FormatarMoedaToLocale(parcela.valorOriginal)}</td>
                                        <td>{_util.FormatarMoedaToLocale(parcela.valorPago)}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </td>
                </tr>
            )
        }
    }
}
export default Financeiro;
