import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as Close } from '../assets/icones/close.svg';

import "./styles.css";

export class ModalBase extends Component {
    static displayName = ModalBase.name;

    render() {
        return (
            <Modal backdrop="static" keyboard={false} dialogClassName={"modal-90w"} autoFocus={true} show={this.props.show} onHide={() => this.props.Fechar()} animation={false} >
                <Modal.Header style={
                    this.props.titulo === undefined
                        ? { display: "none" }
                        : {}
                } closeButton>
                    <h1 ml="15px" fz="24px">
                        {this.props.titulo}
                    </h1>
                </Modal.Header>
                <Modal.Body >
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer style={Object.assign(this.props.backgroundImg !== undefined ? { background: "none" } : {}, styles.footer)}  >
                    {/*<Button*/}
                    {/*    name="Fechar"*/}
                    {/*    onClick={() => this.props.Fechar()}*/}
                       
                    {/*    style={styles.btn}*/}
                    {/*>*/}


                    {/*    {this.props.DescBtnFechar !== undefined*/}
                    {/*        ? this.props.DescBtnFechar*/}
                    {/*        : "Fechar"}*/}
                    {/*</Button>*/}
                    <Button
                        name="Cancelar"
                        style={
                            this.props.DescBtnSecundario === undefined
                                ? { display: "none" }
                                : {}
                        }
                        onClick={() => this.props.BtnSecundario()}
                        width="156px"
                    >
                        {this.props.DescBtnSecundario !== undefined
                            ? this.props.DescBtnSecundario
                            : "Cancelar"}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
}

const styles = {
    footer: {
        "justifyContent": "center"
    },
    btn: { "width": "342.05px", "height": "50px", "backgroundColor": "#0068B2", "borderRadius": "6px", "fontFamily": "Inter", "fontStyle": "normal", "fontWeight": "600", "fontSize": "16px", "lineHeight": "24px" }

}