import React, { useState } from 'react';
import SideBarApp from './sidebarapp/sidebarapp';
import SideBarToggler from './sidebarapp/sidebarapp-toggler';

const SideBar = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [sidebarHovered, setSidebarHovered] = useState(false);

    const togglerHandler = () => {
        setSidebarOpen(!sidebarOpen);
        if (props.SideBarStatus)
            props.SideBarStatus(!sidebarOpen);
    }

    return (<>
        <div className={`container page-wrapper default-theme border-radius-on toggled ${!sidebarOpen ? 'pinned' : ''}`}>
            
            <SideBarApp
                handleHover={() => setSidebarHovered((sidebarOpen ? false : !sidebarHovered))}
                opcoes={props.opcoes}>
                <img src={sessionStorage.getItem('@SECRETARIA/logoSistema')} className='ImagemInicio' />
                <h5 className="sideBarSecretaria">Secretaria Web</h5>
                
            </SideBarApp>
        </div>
  
    </>);
}

export default SideBar;