export function ListByIdUnidade(idUnidade, matriculaId, tipoItem) {
    
    return fetch(`api/curso/ListByIdUnidade/${idUnidade}/${matriculaId}/${tipoItem}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar turma.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar turma.");
            console.log(error.message);
        });
}