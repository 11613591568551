export function buscarCep(cep) {

    return (cep == null || cep == undefined || cep.length < 8 ? false : fetch("api/cep/get/" + cep.replace("-", ""), {
        method: "GET",
        mode: "cors",
    })
        .then((res) => res.json(), error => alert(error.message)
        )
        .then((result) => {
            return result;
        },
            (error) => {
                return false;
                console.log(error);
            })
    )

}