import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/app.css';
import './styles/scss/app.scss';
import msal from "@azure/msal-browser"

import App from './App';

import { PublicClientApplication, EventType } from '@azure/msal-browser';

const pca = new PublicClientApplication({
    auth: {
        clientId: '3d90cd04-7efd-4151-a764-201e5959e56e',
        authority: 'https://login.microsoftonline.com/be041442-f3b6-4be1-8509-eca9c817528f',
        redirectUri: window.location.href,
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPII) => {
                if (containsPII) {
                    return;
                }
                switch (level) {
                    case 0: //msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case 1: //msal.LogLevel.Warning:
                        console.info(message);
                        return;
                }
            },
            logLevel: "Verbose"
        }
    }
});

pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
        console.log(event.payload.account);
        pca.setActiveAccount(event.payload.account);
    }
});

const rootElement = document.getElementById('root');

const IndexApp = () => {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    return (
        <React.StrictMode>
            <BrowserRouter basename={baseUrl}>
                <App msalInstance={pca}/>
            </BrowserRouter>
        </React.StrictMode>
    );
}

ReactDOM.render(<IndexApp />, rootElement);
