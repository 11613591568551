import React, { Component } from "react";
import renderHTML from 'react-render-html';
import '../../../styles/css/app.css';
import * as _matriculaService from '../../../service/matricula-service';
import * as _integracaoMatriculaService from "../../../service/integracaoMatricula-service.js";
import * as _anexo from "../../../Helpers/anexo"
import * as formValidacao from "../../../Helpers/form-validacao.js";
import * as Message from "../../shared/Message";

import {
    PersonFill,
    CurrencyDollar,
    PeopleFill
} from 'react-bootstrap-icons';

class ModalMatricula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsaveis: [],
            Matricula: this.props.Matricula,
            isLoading: false,
            UrlContratoAtual: "",
            Contratos: [],
            params: {
                metodoPagamentoEntrada: "",
                metodoPagamentoParcelamento:""
            },
            loadingPreview: false,
            loadingContrato: false,
            criarContrato: false,
            previewContrato: true,
            errors: {}
        }
    }
    async componentDidMount() {
        await this.GetMatricula();
    }

    async GetMatricula() {
        var matricula = await _matriculaService.GetMatricula(this.state.Matricula.idMatricula);
        if (matricula != null && matricula.urlContrato != "" && matricula.urlContrato != null && matricula.urlContrato != undefined) {
            await this.Convert(matricula.urlContrato);
        }
    }
    async Preview(criarContrato=false) {
        if (!this.FormValidation()) {
            return false;
        }
        var params = this.state.params;
        var request = {
            idMatricula: this.state.Matricula.idMatricula,
            descricaoPagamentoMensalidade: params.metodoPagamentoParcelamento,
            descricaoPagamentoEntrada: params.metodoPagamentoEntrada,
            criarContrato: criarContrato
        }

        var result = await _integracaoMatriculaService.Contrato(request);

        if (result && result.statusCode == 200) {
            var contratos = result.value;
            if (contratos && Array.isArray(contratos) && criarContrato == false) {
                this.setState({ Contratos: contratos, criarContrato: true, previewContrato: true });
            } else {
                await this.GetMatricula();
                Message.ExibeMensagem("Contrato Gerado Com Sucesso!", "0", false);
                this.setState({ Contratos: contratos, criarContrato: false, previewContrato: true});
            }
        } else {
            if (result && result.value) {
                Message.ExibeMensagem(result.value, "2", false);
            } else {
                Message.ExibeMensagem("Erro ao buscar ou gerar contrato!", "2", false);
            }
            this.setState({ criarContrato: false, previewContrato:true });
        }
    }
    async VerPreview() {
        this.setState({ loadingPreview: true, criarContrato: false, previewContrato: false });
        await this.Preview(false);
        this.setState({ loadingPreview: false });
    }
    async CriarContrato() {
        this.setState({ loadingContrato: true, previewContrato: false, criarContrato: false });
        await this.Preview(true);
        this.setState({ loadingContrato: false });
    }
    onChange(event) {
        var params = this.state.params;
        params[event.target.name] = event.target.value;

        this.setState({ params: params });
    }
    async Convert(url) {
        await _anexo.ConvertUrlToBlob(url).then(response => {

            let preview = "";
            let type = "application/jpg";
            if (url.includes(".pdf")) {
                preview = response.replace("octet-stream", "pdf");
                type = "application/pdf";
            } else {
                preview = response.replace("octet-stream", "jpg");
            }
            this.setState({ UrlContratoAtual: preview});
        })
    }
    FormValidation() {
        let errors = {};
        let formIsValid = true;
        let inputs = this.state.params;

        if (!formValidacao.isValidTexto(inputs.metodoPagamentoEntrada, 3)) {
            errors["metodoPagamentoEntrada"] = "Campo obrigatório.";
            formIsValid = false;
        } else {
            errors["metodoPagamentoEntrada"] = "";
            formIsValid = true;
        }
        if (!formValidacao.isValidTexto(inputs.metodoPagamentoParcelamento, 3)) {
            errors["metodoPagamentoParcelamento"] = "Campo obrigatório.";
            formIsValid = false;
        } else {
            errors["metodoPagamentoParcelamento"] = "";
            formIsValid = true;
        }
            

        this.setState({ errors: errors });
        return formIsValid;
    }
    render() {
        const { UrlContratoAtual, isLoading, Contratos, loadingPreview, params, criarContrato, previewContrato, loadingContrato } = this.state;
        if (isLoading) {
            return (<div><h4>Loading... <img width="25" height="25" src="img/loading.gif" /></h4></div>);
        } else {
            return (
                <div className="row p-b-10">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Metodo Pagamento Entrada</label>
                                <input
                                    style={{ border: "solid 1px blue"}}
                                    className={
                                        "form-control " +
                                        formValidacao.cssTexto(
                                            this.state.errors["metodoPagamentoEntrada"],
                                            params.metodoPagamentoEntrada,
                                            3
                                        )
                                    }
                                    name="metodoPagamentoEntrada"
                                    onChange={(e) => this.onChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Metodo Pagamento Parcelamento</label>
                                <input
                                    style={{ border: "solid 1px blue" }}
                                    className={
                                        "form-control " +
                                        formValidacao.cssTexto(
                                            this.state.errors["metodoPagamentoParcelamento"],
                                            params.metodoPagamentoParcelamento,
                                            3
                                        )
                                    }
                                    name="metodoPagamentoParcelamento"
                                    onChange={(e) => this.onChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-primary" disabled={!previewContrato ? true : false} onClick={async () => { this.VerPreview()}}>Preview novo contrato {loadingPreview ? <img width="25" height="25" src="img/loading.gif" /> : ""}</button>
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-primary" disabled={!criarContrato ? true : false} onClick={async () => { this.CriarContrato() }}>Criar contrato {loadingContrato ? <img width="25" height="25" src="img/loading.gif" /> : ""}</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h6>Contrato Atual</h6>
                            {
                                (UrlContratoAtual == "" || UrlContratoAtual == undefined) && !isLoading ? "Sem contrato..." : <iframe type="application/pdf" width="100%" height="400" src={UrlContratoAtual}></iframe>
                            }
                            
                        </div>
                        <div className="col-md-6">
                            {Contratos.length > 0 ? <h6>Novo Contrato</h6> : ""}
                            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                            {
                                Contratos && Contratos.map((contrato, index) => {
                                    return  renderHTML(contrato.servicoUnidadeContratoHTML)
                                })
                            }
                            </div>
                        </div>
                    </div>
                   
                </div>
            );
        }
    }

}
export default ModalMatricula;