import React, { Component } from 'react';
import { ModalClean } from '../../shared/ModalClean';
import { ModalBase } from '../../shared/ModalBase';
import { Dropdown, Button, DropdownButton, Row } from "react-bootstrap";
import * as Message from '../../shared/Message';
import * as _matriculaService from '../../../service/matricula-service.js';
import * as formValidacao from "../../../Helpers/form-validacao.js";

export class Motivo_cancelamento extends Component {
    static displayName = Motivo_cancelamento.name;

    constructor(props) {
        super(props);
        this.state = {
            exibeModal: false,
            isLoading: false,
            errors: {},
            motivosCancelamento: [],
            motivoEditar: {},
            modoInclusao:false,
            matricula: "",
            camposBusca: {
                matricula: "",
                anoLetivo:"",
                estudante_Name: "",
                codigoCliente: ""
            }
        };
        this.CarregarMotivosCancelamento = this.CarregarMotivosCancelamento.bind(this);
        this.InserirNovoMotivocancelamento = this.InserirNovoMotivocancelamento.bind(this);

        this.AtualizarMotivoCancelamento = this.AtualizarMotivoCancelamento.bind(this);

    }
    ModoInclusao() {
        this.setState({ motivoEditar: {} });
        this.setState({ modoInclusao: true });

    }

    async AtualizarMotivoCancelamento() {
        this.setState({ isLoading: true });

        if (this.state.motivoEditar.id) {
            _matriculaService.AtualizarMotivoCancelamento(this.state.motivoEditar);
            this.CarregarMotivosCancelamento();
        } 
        this.setState({ isLoading: false });
    }

    async DeletaMotivoCancelamento() {
        this.setState({ isLoading: true });

        if (this.state.motivoEditar && this.state.motivoEditar.id) {
            this.setState({ isLoading: true });
            _matriculaService.DeletarMotivoCancelamento(this.state.motivoEditar).then(() => {
                this.CarregarMotivosCancelamento();
            });;
            return;
        } else {

        }

    }

    async InserirNovoMotivocancelamento() {
        this.setState({ isLoading: true });
        _matriculaService.IncluirNovoMotivoCancelamento(this.state.motivoEditar).then(() => {
            this.CarregarMotivosCancelamento();
        });;


    }


    async CarregarMotivosCancelamento() {
        this.setState({ isLoading: true, motivoEditar: {}  });
        var motivosCancelemento = await _matriculaService.ListMotivosCancelamento();

        if (motivosCancelemento != null) {
            this.setState({ motivosCancelamento: motivosCancelemento });
        }
        this.setState({ isLoading: false });
    }

    async componentDidMount() {
       
        this.CarregarMotivosCancelamento();
        
            if (sessionStorage.getItem('@SECRETARIA/unidade') == undefined ||
                sessionStorage.getItem('@SECRETARIA/unidade') == '' ||
                sessionStorage.getItem('@SECRETARIA/unidade') == null) {

                Message.ExibeMensagem("Selecione a unidade", "1", true);
                setTimeout(() => {
                    window.location = "/";
                }, "3000")

            }
        
    }


    render() {
        return (
            <div className="panel">
                <h2 class="tituloCss">Motivos cancelamento</h2>
                <div className="panel-body p-25">
                    <div className="col-md-12 col-lg-12">
                
                <Row>
                    <div className=" form-group">
                        <label>Código</label>
                        <input style={{ width: '250px' }} className={"form-control "} name="matricula" value={this.state.motivoEditar?.codigo || ''} disabled={this.state.motivoEditar.id || this.state.isLoading} onChange={(e) => this.setState({
                            motivoEditar: {
                                ...this.state.motivoEditar,
                                codigo: e.currentTarget.value
                            }
                        })} disabled={this.state.motivoEditar.id || this.state.isLoading || !this.state.modoInclusao} />
                    </div>
                    <div className=" form-group">
                    <label>Descricão</label>
                        <input style={{ width: '250px' }} className={"form-control "} name="matricula" value={this.state.motivoEditar?.descricao || ''} onChange={(e) => this.setState({
                            motivoEditar: {
                                ...this.state.motivoEditar,
                                descricao: e.currentTarget.value
                            }
                        })} disabled={this.state.isLoading || (!this.state.modoInclusao && !this.state.motivoEditar.id)} />
                    </div>
                    </Row>
                <div className={"d-flex justify-content-left"}>
                    <Button
                        size="lg"
                        name="Deletar"
                        variant="danger"
                        disabled={this.state.isLoading}
                        onClick={() => { this.DeletaMotivoCancelamento() }}
                        width="156px" style={!this.state.motivoEditar.id ? { display: 'none' } : { marginTop: '50px', marginRight: '16px' }} >

                        {this.state.isLoading ? 'Deletando…' : 'Deletar'}

                    </Button>
                    <Button
                        size="lg"
                        name="Incluir novo"
                                className="btn btn-light"
                        disabled={this.state.isLoading}
                        onClick={() => { this.ModoInclusao(); }}
                        width="156px" style={this.state.modoInclusao ? { display: 'none' } : { marginTop: '50px', marginRight: '16px' }}>

                        {'Incluir novo'}

                    </Button>
                    <Button
                        size="lg"
                        name="Confirmar inclusão"
                        variant="primary"
                        disabled={this.state.isLoading}
                        onClick={() => { this.InserirNovoMotivocancelamento() }}
                        width="156px" style={!this.state.modoInclusao ? { display: 'none' } : { marginTop: '50px', marginRight: '16px' }}>

                        {this.state.isLoading ? 'Registrando…' : 'Confirmar inclusão'}

                    </Button>
                    <Button
                        size="lg"
                        name="Confirmar"
                        variant="success"
                        disabled={this.state.isLoading}
                        onClick={() => { this.AtualizarMotivoCancelamento() }}
                        width="156px" style={this.state.modoInclusao || !this.state.motivoEditar.id ? { display: 'none' } : { marginTop: '50px' }}>

                        {this.state.isLoading ? 'Salvando…' : 'Salvar alteração'}

                    </Button>
                </div>
                <div className="filtros-body mt-2">
                    <strong>*Selecione abaixo para editar ou excluir </strong>
                    <div className="table-responsive">
                        <table id="data-table" className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descricao</th>
                                  </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.motivosCancelamento.map((motivo, index) => {
                                        return (
                                            <tr key={index} class="odd gradeX" style={{ cursor: 'pointer' }} onClick={() => {
                                                this.setState({
                                                    motivoEditar: motivo,
                                                    modoInclusao: false

                                                })
                                            }}>
                                                <td>{motivo.codigo}</td>
                                                <td>{motivo.descricao}</td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        );
    }
}
