import {
    HouseFill,
    StarFill,
    Shop,
    Signpost2Fill,
    FileBinary,
    FileEarmarkPlus,
    ClipboardCheck,
    JournalBookmarkFill,
    FileEarmarkArrowDown
} from 'react-bootstrap-icons';

const ItensDeMenuSitemIdeBar = [
    {
        itemId: 1,
        title: 'Início',
        link: '/home',
        icon: <i class="menuHome" />,
        show: true
    },
    {
        itemId: 2,
        title: 'Cadastros',
        link: null,
        icon: <i class="menuCadastro" />,
        iconactive: <i class="menuCadastroActive" />,
        submenu: [
            //    {
            //        itemId: 21,
            //        title: 'Motivo de Cancelamento',
            //        icon: <FileEarmarkArrowDown />,
            //        link: '/cadastromotivoscancelamento'
            //},
        ],
        show: true
    },
    {
        itemId: 3,
        title: 'Secretaria',
        link: null,
        icon: <i class="menuSecretaria" />,
        iconactive: <i class="menuSecretariaActive" />,
        submenu: [
            { itemId: 31, title: 'Movimentações', link: '/movimentacoes', icon: <Signpost2Fill />, show: true },
            { itemId: 32, title: 'Matrículas', link: '/matriculas', icon: <JournalBookmarkFill />, show: true },
            // { itemId: 33, title: 'Painel Conciliação Matricula', link: '/painel-conciliacao-matricula', icon: <JournalBookmarkFill />, show: true }
        ],
        show: true
    },
    {
        itemId: 4,
        title: 'Integrações',
        link: null,
        icon: <i class="menuSecretaria" />,
        iconactive: <i class="menuSecretariaActive" />,
        submenu: [
           /* { itemId: 41, title: 'Matrículas', link: '/integracoes', icon: <JournalBookmarkFill />, show: false },*/
            { itemId: 42, title: 'Replay Total', link: '/replay-integracao-total', icon: <JournalBookmarkFill />, show: false },
            { itemId: 43, title: 'Contrato', link: '/contrato', icon: <JournalBookmarkFill />, show: false },
            { itemId: 44, title: 'Replay Parcial', link: '/replay-integracao-parcial', icon: <JournalBookmarkFill />, show: false },
            { itemId: 45, title: 'Replay Total UpSelling', link: '/replay-integracao-upselling-total', icon: <JournalBookmarkFill />, show: false },
            { itemId: 46, title: 'Replay Parcial UpSelling', link: '/replay-integracao-upselling-parcial', icon: <JournalBookmarkFill />, show: false },
            { itemId: 47, title: 'Replay Parcial Regular', link: '/replay-integracao-regular-parcial', icon: <JournalBookmarkFill />, show: false }
        ],
        show: true
    }
];

export default ItensDeMenuSitemIdeBar;
