export function GetMultaCancelamento(idMatricula) {
    return fetch("api/Cancelamento/RecuperaMulta/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error: "Ocorreu um erro contate o administrador.",
                });
                console.log(error);
            }
        )
        .catch((error) => {
            console.log(error.message);
        });
}


export function PostCriarCancelamento(request) {
    return fetch("api/Cancelamento/Criar", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao criar cancelamento.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao criar cancelamento.");
            console.log(error.message);
        });
}