import React from 'react';
import AdalContext from '../Helpers/adal-config';
import {
    Container,
    NavDropdown,
    Nav,
    Navbar,
    Badge,
    Image,
} from 'react-bootstrap';
import { Bell } from 'react-bootstrap-icons'
import '../styles/css/app.css';
import avatarimg from '../styles/img/user-11.jpg';
import * as _unidadeService from '../service/unidade-service.js';

export class HearderApp extends React.Component {
    static displayName = HearderApp.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            unidade: null,
            unidades: []
        };
    }

    async componentDidMount() {

        var listUnidades = await _unidadeService.GetAll();

        if (listUnidades != null && listUnidades != null) {
            this.setState({ unidades: listUnidades.hasOwnProperty('length') ? listUnidades : [] });
        }

        if (sessionStorage.getItem('@SECRETARIA/unidade') != undefined && sessionStorage.getItem('@SECRETARIA/unidade') != '') {
            this.setState({ unidade: JSON.parse(sessionStorage.getItem('@SECRETARIA/unidade')) })
        }

        if (this.state.unidade == null || this.state.unidade == undefined) {
            sessionStorage.setItem('@SECRETARIA/logoSistema', '');
            sessionStorage.setItem('@SECRETARIA/idUnidade', '');
            sessionStorage.setItem('@SECRETARIA/unidade', '');
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    LogOut() {
        const adalContext = new AdalContext();
        adalContext.LogOut();
    }

    SelecioneUnidade(obj) {
        this.setState({ unidade: obj });

        sessionStorage.setItem('@SECRETARIA/unidade', JSON.stringify(obj));
        sessionStorage.setItem('@SECRETARIA/logoSistema', obj.logo);
        sessionStorage.setItem('@SECRETARIA/idUnidade', obj.id);
        sessionStorage.setItem('@SECRETARIA/entidadeLegal', obj.codigoERP);
        sessionStorage.setItem('@SECRETARIA/dataAreaId', obj.dataAreaId);

        window.location = "/";
    }

    render() {
        return (
            <header>
                <div className="row header">
                    <div className="col-md-10">
                        <Nav className='me-auto ddlUnidade'>
                            <NavDropdown className='Fundounidade ddlFundoBranco' title={(this.state.unidade != null && this.state.unidade != undefined ? this.state.unidade.nome : "Selecione a unidade...")} id='collasible-nav-dropdown'>
                                {this.state.unidades?.map((unidade, idx) => (

                                    <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneUnidade(unidade)} href='#'>
                                        {unidade.nome}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        </Nav>
                    </div>
                    <div className="col-md-2">
                        <Nav className='navbar-user Fundounidade'>
                            <NavDropdown
                                menuVariant="blue"
                                title={sessionStorage.getItem('@SECRETARIA/nomeUsuarioLogado')}
                            >
                                <NavDropdown.Item onClick={() => this.LogOut()} href='#'>Sair</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </div>

                </div>

            </header>
        );
    }
}
