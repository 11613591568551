import React, { Component } from "react";
import * as Message from '../shared/Message';

export class ReplayIntegracaoUpSellingTotal extends Component {
    static displayName = ReplayIntegracaoUpSellingTotal.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            matriculaCrm:"",
            result: {},
            selectedFile: null,
        };
    }

    async componentDidMount() {


    }


    change(event) {

        this.setState({ matriculaCrm: event.target.value});
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ result: {} })

        this.setState({ isLoading: true });

        await fetch("api/IntegracaoMatricula/ReplayTotalUpSelling/" + this.state.matriculaCrm, {
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "*/*",
            },
            body: {},
        })
            .then((res) => {
                this.setState({ isLoading: false });
                return res.json();
            })
            .then(
                (result) => {
                    this.setState({ isLoading: false });
                    this.setState({ result: result });

                    return result;
                },
                (error) => {
                    this.setState({ isLoading: false });
                    this.setState({ result: error });
                    console.log(error);
                }
            )
            .catch((error) => {
                this.setState({ isLoading: false });
                this.setState({ result: error });
                console.log(error.message);
            });

        this.setState({ isLoading: false });

        if (this.state.result != null && this.state.result != undefined) {

            Message.ExibeListMensagem(this.state.result.messages);
        } else {
            Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
        }
    };

    render() {
        const { base64 } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Total</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Total de UpSelling</h2>
                    <div className="panel-body p-25 mb-3">
                        <div className="col-md-12 col-lg-12">

                            <form onSubmit={this.handleSubmit} asp-controller="CheckOut" asp-action="ReplayIntegracaoUpSellingTotal" >
                                <p>
                                    <strong>Código Matrícula UpSelling</strong>
                                </p>
                                <div className="row">

                                    <div className="col-md-2 col-lg-2">
                                        <input type="text" className={"txtBorder form-control "} name={"matriculaCrm"} onChange={event => this.change(event)} />
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <button className="btn primary mb-3" type="submit">Enviar</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
