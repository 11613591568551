export function GetAll() {

    return fetch("api/Unidade/GetAll", {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
        .catch(error => {
            return error;
        });
}

export function GetByCodigoUnidade(codigoUnidadeLista) {
    return fetch("api/Unidade/GetByCodigoUnidade", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(codigoUnidadeLista)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert(error.message);
                return false;
            }
        )
        .catch(error => {
            alert(error.message);
            return false;
        });
}