export function ObterListagemGrauParentesco() {
    return fetch("api/conjuntoopcao/get/contact/seb_grau_parentesco", {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar grau de parentesco.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar grau de parentesco.");
            console.log(error.message);
            return false;
        });
}