import React, { Component } from "react";
import * as _integracaoMatriculaService from "./../../service/integracaoMatricula-service.js";
import * as Message from '../shared/Message';
import { Table } from "react-bootstrap";
import * as formValidacao from "../../Helpers/form-validacao.js";

export class ReplayIntegracaoTotal extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            selecionarTodos: false,
            data: null,
            matriculas: [],
            errors: {}
        };
    }

    buscar = async () => {
        this.setState({ isLoading: true });
        const result = await _integracaoMatriculaService.GetIntegracaoTotalList(this.state.data);
        this.setState({ isLoading: false, matriculas: result });
    }

    enviar = async (matricula) => {

        this.setState({ isLoading: true });
        const result = await _integracaoMatriculaService.Replay(matricula);
        this.setState({ isLoading: false });
        if (result != undefined && result.value != undefined) {
            if (result.statusCode == 200) {
                Message.ExibeMensagem('Replay de integrações finalizado com SUCESSO', '0', false);
                await this.buscar();
            } else {
                Message.ExibeMensagem(result.value, '2', false);
            }
        } else {
            Message.ExibeMensagem('Replay de integrações finalizado com ERRO', '2', false);
        }
    }

    marcarJson = (matriculaId, checked) => {
        let matriculas = this.state.matriculas;
        let matricula = this.state.matriculas.find(x => x.id === matriculaId);
        let matriculaIndex = this.state.matriculas.findIndex(x => x.id === matriculaId);

        matricula.checked = checked;

        if (matriculaIndex != -1) {
            this.state.matriculas[matriculaIndex] = matricula;
            this.setState({ matriculas: matriculas });
        }
    }

    render() {
         if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Total</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Total</h2>
                    <div className="panel-body p-25">
                        <div className="col-md-12 col-lg-12">
                            <div className="filtros">
                                <form onSubmit={(e) => this.Buscar(e)}>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Data</label>
                                                <input
                                                    type="date"
                                                    className={
                                                        "form-control " +
                                                        formValidacao.cssTexto(
                                                            this.state.errors["dataInicio"],
                                                            this.state.data
                                                        )
                                                    }
                                                    name="dataInicio"
                                                    value={this.state.data}
                                                    onChange={(e) => this.setState({ data: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <input
                                                className="btn btn-primary"
                                                type="submit"
                                                disabled={this.state.isLoading ? "disabled" : ""}
                                                value={this.state.isLoading ? "Buscando..." : "Buscar"}
                                                style={{ marginTop: 15 }}
                                                onClick={() => this.buscar()}
                                            />
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <Table className="mt-2 tableStyle" bordered responsive style={{ backgroundColor: "#f5f5f5" }}>
                                <thead>
                                    <tr>
                                        <th>Matricula CRM</th>
                                        <th>Matricula ERP</th>
                                        <th>Ano Letivo</th>
                                        <th>Nome do Aluno</th>
                                        <th>Curso</th>
                                        <th>Turma</th>
                                        <th>Tipo de Matricula</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state?.matriculas.map((matricula, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                style={
                                                    matricula?.selected == true
                                                        ? {
                                                            backgroundColor: "#cae9ef",
                                                            color: "#ffffff",
                                                            cursor: "pointer",
                                                        }
                                                        : { cursor: "pointer" }
                                                }
                                            >
                                                <td>{matricula.codigoMatricula}</td>
                                                <td>{matricula.codigoMatriculaErp}</td>
                                                <td>{matricula.anoLetivo_Name}</td>
                                                <td>{matricula.estudante_Name}</td>
                                                <td>{matricula.curso_Name}</td>
                                                <td>{matricula.turma_Name}</td>
                                                <td>{matricula?.tipoMatricula?.descricao}</td>
                                                <td>
                                                    <button className="btn btn-primary" onClick={() => this.enviar(matricula)}>Enviar</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
