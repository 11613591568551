import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table'
import React, { Component } from "react";
import './modal-integracao.css';
import * as _integracaoMatriculaService from "./../../service/integracaoMatricula-service.js";
import * as Message from '../shared/Message';

export class ModalIntegracao extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            selecionarTodos: false,
            matriculas: []
        };
    }

    componentDidMount() {
        this.setState({ matriculas: this.props.matricula?.integrationErrorLogList });
    }

    onSelectAll = (checked) => {
        this.setState({ selecionarTodos: checked });
        let matriculas = this.state.matriculas;

        matriculas.forEach(matricula => {
            matricula.selected = checked;
        });

        this.setState({ matriculas: matriculas });
    }

    onSelect = (matriculaObj, checked) => {
        let matriculas = this.state.matriculas;

        let matricula = this.state.matriculas.find(x => x.logId === matriculaObj.logId);
        matricula.selected = checked;

        var index = this.state.matriculas.findIndex(x => x.logId === matriculaObj.logId)
        matriculas[index] = matricula;

        if (!checked) {
            this.setState({ selecionarTodos: checked });
        }

        this.setState({ matriculas: matriculas });
    }

    handleInputPayload = (event, index) => {
        let matriculas = this.state.matriculas;

        let matricula = matriculas[index];
        matricula.payload = event.target.value;

        matriculas[index] = matricula;

        this.setState({ matriculas: matriculas });
    }

    reenviar = async () => {
        this.setState({ isLoading: true });
        let matriculasSelecionadas = [];
        this.state.matriculas.forEach(x => {
            if (x?.selected) {
                matriculasSelecionadas.push({
                    LogId: x.logId,
                    Payload: x.payload
                });
            }
        })

        if (matriculasSelecionadas?.length === 0) {
            Message.ExibeMensagem('Selecione uma matricula com erro para fazer o replay', '2', false);
            return;
        }

        const integrationReplayItemsList = [{
            CodigoMatriculaErp: this.props.matricula?.codigoMatriculaErp,
            IntegrationReplayItemsDetailsList: matriculasSelecionadas
        }];

        const request = {
            IntegrationReplayItemsList: integrationReplayItemsList
        }

        var result = await _integracaoMatriculaService.ReplayIntegrations(request);

        this.setState({ isLoading: false });

        if (!result?.statusResult || result?.statusResult?.length == 0) {
            Message.ExibeMensagem('Replay de integrações finalizado com ERRO', '2', false);
        } else {
            this.props.onClose(true);
            Message.ExibeMensagem('Replay de integrações finalizado com SUCESSO', '0', false);
        }
    }

    render() {
        if (this.state?.matriculas?.length > 0) {
            return (
                <div>
                    <Modal show={this.props.show} onHide={this.props.onClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalhes Payload</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table responsive="md" className="mt-2 table-style" bordered style={{ backgroundColor: "#f5f5f5" }}>
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" checked={this.state.selecionarTodos} onClick={(event) => this.onSelectAll(event.target.checked)}></input></th>
                                        <th>Tipo</th>
                                        <th>Mensagem Erro</th>
                                        <th>Payload</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state?.matriculas.map((matricula, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                style={
                                                    matricula?.selected == true
                                                        ? {
                                                            backgroundColor: "#cae9ef",
                                                            color: "#ffffff",
                                                            cursor: "pointer",
                                                        }
                                                        : { cursor: "pointer" }
                                                }

                                            >
                                                <td><input type="checkbox" checked={matricula?.selected ? true : false} onClick={(event) => { this.onSelect(matricula, event.target.checked) }} /></td>
                                                <td>{matricula?.tipoProcessoDescricao}</td>
                                                <td>{matricula?.mensagemErro}</td>
                                                <td>
                                                    <input type={'textarea'} value={matricula?.payload} onChange={(e) => this.handleInputPayload(e, index) } />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='container-btns'>
                                <Button className='button-action' variant="primary" onClick={() => this.reenviar()}>Reenviar</Button>
                                <Button className='button-action' variant="secondary" onClick={this.props.onClose}>Fechar</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            );

        } else {
            return (<></>);
        }
        // else if (this.state?.matriculas?.length === 0) {
        //     return (
        //         <div className="panel">
        //             <div className="panel-body p-25">
        //                 <h4>Não há detalhes para visualização</h4>
        //             </div>
        //         </div>
        //     );
        // } else if (this.state?.isLoading === true) {
        //     return (
        //         <div className="panel">
        //             <div className="panel-body p-25">
        //                 <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
        //             </div>
        //         </div>
        //     );
        // }
    }
};