import React, { Component } from "react";
import * as Message from '../shared/Message';

export class ReplayIntegracaoRegularParcial extends Component {
    static displayName = ReplayIntegracaoRegularParcial.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            matriculaCrm: "",
            qntParcelaRegular: "",
            qntParcelaMaterial: "",
            dataReferencia: "",
            result: {},
            request: {},
            selectedFile: null,
        };
    }

    async componentDidMount() {


    }

    changeCheckBox(event) {

        var request = this.state.request;

        if (event.target.name == "ficha") {
            if (event.target.checked) {
                request.enviarFichaFinanceira = true;
            } else {
                request.enviarFichaFinanceira = false;
            }
        }

        if (event.target.name == "transacao") {
            if (event.target.checked) {
                request.enviarTransacaoFinanceira = true;
            } else {
                request.enviarTransacaoFinanceira = false;
            }
        }

        if (event.target.name == "servicomatricula") {
            if (event.target.checked) {
                request.enviarServicoMatricula = true;
            } else {
                request.enviarServicoMatricula = false;
            }
        }

        if (event.target.name == "ov") {
            if (event.target.checked) {
                request.enviarOrdemVenda = true;
            } else {
                request.enviarOrdemVenda = false;
            }
        }

        this.setState({ request: request });
    }

    change(event) {

        if (event.target.name == "qntParcelaRegular") {
            this.setState({ qntParcelaRegular: event.target.value });
        }
        else if (event.target.name == "qntParcelaMaterial") {
            this.setState({ qntParcelaMaterial: event.target.value });
        }
        else if (event.target.name == "dataReferencia") {
            this.setState({ dataReferencia: event.target.value });
        }
        else {
            this.setState({ matriculaCrm: event.target.value });
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ result: {} })

        this.setState({ isLoading: true });
        debugger
        await fetch("api/IntegracaoMatricula/ReplayParcialRegular/" + this.state.matriculaCrm + "/" + this.state.qntParcelaRegular + "/" + this.state.qntParcelaMaterial + "/" + this.state.dataReferencia, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.state.request),
        })
            .then((res) => {
                this.setState({ isLoading: false });

                return res.json();
            })
            .then(
                (result) => {
                    this.setState({ isLoading: false });
                    this.setState({ result: result });
                    this.setState({ request: {} });

                    return result;
                },
                (error) => {
                    this.setState({ isLoading: false });
                    this.setState({ result: error });
                    this.setState({ request: {} });
                    console.log(error);
                }
            )
            .catch((error) => {
                this.setState({ isLoading: false });
                this.setState({ result: error });
                this.setState({ request: {} });
                console.log(error.message);
            });

        this.setState({ isLoading: false });

        if (this.state.result != null && this.state.result != undefined) {

            Message.ExibeListMensagem(this.state.result.messages);
        } else {
            Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
        }
    };

    render() {
        const { base64 } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Parcial de Serviço Regular</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="panel">
                    <h2 class="tituloCss">Replay Integração Parcial de Serviço Regular</h2>
                    <div className="panel-body p-25 mb-3">
                        <div className="col-md-12 col-lg-12">

                            <form onSubmit={this.handleSubmit} asp-controller="CheckOut" asp-action="ReplayIntegracaoUpSellingTotal" >

                                <div className="row">
                                    <div className="col-md-3 col-lg-3">
                                        <strong>Código Matrícula Serviço Regular</strong>
                                        <input type="text" className={"txtBorder form-control "} name={"matriculaCrm"} onChange={event => this.change(event)} />
                                    </div>
                                    <div className="col-md-3 col-lg-3">
                                        <strong>Data de Referência</strong>
                                        <input type="date" className={"txtBorder form-control "} name={"dataReferencia"} onChange={event => this.change(event)} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    
                                    <div className="col-md-3 col-lg-3">
                                        <strong>Quantidade Parcela Regular</strong>
                                        <input type="number" className={"txtBorder form-control "} name={"qntParcelaRegular"} onChange={event => this.change(event)} />
                                    </div>
                                    
                                    <div className="col-md-3 col-lg-3">
                                        <strong>Quantidade Parcela Material</strong>
                                        <input type="number" className={"txtBorder form-control "} name={"qntParcelaMaterial"} onChange={event => this.change(event)} />
                                    </div>
                                 
                                </div>
                                <br />

                                <p>Para fornecer transação á vista, inserir valor 0 na quantidade de parcela</p>
                                <p>Caso um dos itens já existir no ERP ser atentar a quantide de parcelas para encaminhar os demais itens</p>
                                <div className="row">
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="ficha" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Ficha
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="transacao" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Transações
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="servicomatricula" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Serviço Matricula
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="ov" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Ordem de Venda
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <button className="btn primary mb-3" type="submit">Enviar</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
