import * as Message from '../components/shared/Message';



export function ListMatriculas(request) {
    return fetch("api/Movimentacoes/Matriculas", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao listar matriculas.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao listar matriculas.");
            console.log(error.message);
        });
}
export function GetMatricula(idMatricula) {
    return fetch("api/MovimentacoesResponsavel/GetMatricula/" + idMatricula, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar matricula.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar matricula.");
            console.log(error.message);
        });
}


export function ListMotivosCancelamento() {
    return fetch("api/Movimentacoes/GetMotivosCancelamento", {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao listar motivos cancelamento.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao listar  motivos cancelamento.");
            console.log(error.message);
        });
}
export function IncluirNovoMotivoCancelamento(request) {
    return fetch("api/Movimentacoes/InsereNovoMotivoCancelamento    ", {
        method: "PUT",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
       
        .then(
            (result) => {
                return ;
            },
            (error) => {
                alert("Erro ao inserir novo motivo de cancelamento.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao inserir novo motivo de cancelamento.");
            console.log(error.message);
        });
}

export function DeletarMotivoCancelamento(request) {
    return fetch("api/Movimentacoes/DeletarMotivoCancelamento", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })

        .then(
            (result) => {
                return;
            },
            (error) => {
                alert("Erro ao inserir novo motivo de cancelamento.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao inserir novo motivo de cancelamento.");
            console.log(error.message);
        });
}



export function AtualizarMotivoCancelamento(request) {
    return fetch("api/Movimentacoes/UpdateMotivoCancelamento", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })
       
        .then(
            (result) => {
                return ;
            },
            (error) => {
                alert("Erro ao inserir novo motivo de cancelamento.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao inserir novo motivo de cancelamento.");
            console.log(error.message);
        });
}


export function CancelarMatricula(request) {
    return fetch("api/Movimentacoes/CancelarMatricula", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    })

        .then(
            (result) => {
                Message.ExibeMensagem("Matricula cancelada com sucesso.", '0', true);

                return;
            },
            (error) => {
                Message.ExibeMensagem("Erro ao cancelar matricula", '2', true);

                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao inserir novo motivo de cancelamento.");
            console.log(error.message);
        });
}
export function TrocarCursoNovaMatricula(idMatricula, idServicoUnidade, idTurma) {
    return fetch(`api/Movimentacoes/TrocarCursoNovaMatricula/${idMatricula}/${idServicoUnidade}/${idTurma}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        return res.json();
    }).then(
            (result) => {
                return result;
            },
            (error) => {
                Message.ExibeMensagem("Erro ao cancelar matricula", '2', true);

                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao inserir novo motivo de cancelamento.");
            console.log(error.message);
        });
}
export function GetMatriculaGeral(idMatricula) {
    return fetch("api/MatriculaGeral/GetMatriculaGeral/" + idMatricula, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar matricula Geral.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar matricula Geral.");
            console.log(error.message);
        });
}
