export function TurmasServicoUnidade(idServicoUnidade) {

    return fetch(`api/turma/TurmasServicoUnidade/${idServicoUnidade}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            return res.json();
        })
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar turma.");
                console.log(error);
            }
        )
        .catch((error) => {
            alert("Erro ao buscar turma.");
            console.log(error.message);
        });
}

export function AtualizarTurma(idMatricula, idNovaTurma) {
    return fetch(`api/turma/AtualizarTurma/${idMatricula}/${idNovaTurma}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(async (res) => {
            if (!res.ok)
                return res.text()
            try {
                const resp = await res.json();
                if (resp)
                    return resp
            } catch (e) {
                return res.body
            }
        })
        .then(
            (result) => {
                return result;
            },
        )
}
