import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import constructImg from '../styles/img/construct.png';

const Construct = (props) => {

  return (
    <>
      <Row className='justify-content-md-center mt-5'>
      <Col md='auto'>
          <Card style={{ width: '25rem' }}>
            <Card.Img variant='top' src={constructImg} />
            <Card.Body>
              <Card.Title>Opção em Desenvolvimento</Card.Title>
              <Card.Text>
                Aguarde, logo esta opção estará disponível...
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Construct;
