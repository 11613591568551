import { Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { AuthProvider } from "./context/AuthContext";

import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import Construct from './components/construct';

import { Movimentacoes } from './components/movimentacoes/Movimentacoes';
import { Motivo_cancelamento } from './components/Cadastros/MotivosCancelamento/Motivo_cancelamento';
import { Home } from './components/Home';
import { MatriculasGeral } from './components/visaoGeralMatricula/MatriculasGeral';
import { IntegracaoMatriculasGeral } from './components/Integracao/IntegracaoMatriculasGeral';
import { BuscaMatriculaContrato } from './components/Integracao/contrato/BuscaMatriculaContrato';
import { PagamentoAVista } from './components/Pagamentos/pagamento_avista';

import { ReplayIntegracaoTotal } from './components/Integracao/ReplayIntegracaoTotal';
import { ReplayIntegracaoUpSellingTotal } from './components/IntegracaoUpSelling/ReplayIntegracaoUpSellingTotal';
import { ReplayIntegracaoParcial } from './components/Integracao/integracaoParcial/ReplayIntegracaoParcial';
import { ReplayIntegracaoUpSellingParcial } from './components/IntegracaoUpSelling/ReplayIntegracaoUpSellingParcial';
import { ReplayIntegracaoRegularParcial } from './components/Integracao/ReplayIntegracaoRegularParcial';
import { PainelConciliacaoMatricula } from './components/ConciliacaoMatricula/PainelConciliacaoMatricula';

function App({ msalInstance }) {
    return (
        <MsalProvider instance={msalInstance}>
            <AuthProvider>
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/Home' component={Home} />
                    <Route path='/movimentacoes' component={Movimentacoes} />
                    <Route path='/cadastromotivoscancelamento' component={Motivo_cancelamento} />
                    <Route path='/construct' component={Construct} />
                    <Route path='/fetch-data' component={FetchData} />
                    <Route path='/matriculas' component={MatriculasGeral} />
                    <Route path='/integracoes' component={IntegracaoMatriculasGeral} />
                    <Route path='/contrato' component={BuscaMatriculaContrato} />
                    <Route path='/replay-integracao-total' component={ReplayIntegracaoTotal} />
                    <Route path='/pagamento_avista' component={PagamentoAVista} />
                    <Route path='/replay-integracao-upselling-total' component={ReplayIntegracaoUpSellingTotal} />
                    <Route path='/replay-integracao-parcial' component={ReplayIntegracaoParcial} />
                    <Route path='/replay-integracao-upselling-parcial' component={ReplayIntegracaoUpSellingParcial} />
                    <Route path='/replay-integracao-regular-parcial' component={ReplayIntegracaoRegularParcial} />
                    {/*<Route path='/painel-conciliacao-matricula' component={PainelConciliacaoMatricula} />*/}
                </Layout>
            </AuthProvider>
        </MsalProvider>
    );
}

export default App;

