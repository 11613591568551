import React, {useContext, useState, useEffect} from 'react';
import '../styles/css/app.css';
import {AuthContext} from '../context/AuthContext';

const Home = () => {
    const { logoUnidade } = useContext(AuthContext);

    useEffect(() => {

    },[logoUnidade]);

    return (
        <div className="panel">
            <div className="panel-body p-25">
                <div className="col-md-12 col-lg-12">
                    <h1 className="TextoInicioSecre">Secretaria</h1>
                    <div className="row">
                        <img src={logoUnidade} className='ImagemInicio' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Home };
// export class Home extends Component {
//   static displayName = Home.name;

//     constructor(props) {
//         super(props);

//         this.state = {
//             collapsed: true,
//             logo: null
//         };
//     }

//     async componentDidMount() {
//         this.state.logo = sessionStorage.getItem('@SECRETARIA/logoSistema');
//     }

//     render() {
//         return (
//             <div className="panel">
//                 <div className="panel-body p-25">
//                     <div className="col-md-12 col-lg-12">
//                         <h1 className="TextoInicioSecre">Secretaria</h1>
//                         <div className="row">
//                             <img src={sessionStorage.getItem('@SECRETARIA/logoSistema')} className='ImagemInicio' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }
