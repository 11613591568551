
export function BuscaResponsaveisMatricula(request) {
    return fetch("api/MetodoPagamento/BuscaResponsaveisMatricula", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
                console.log(error);
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function BuscaMetodoAtual(request) {
    return fetch("api/MetodoPagamento/BuscaMetodoAtual", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
                console.log(error);
            }
        ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function TrocarMetodoPagamento(request) {
    return fetch("api/MetodoPagamento/TrocarMetodoPagamento", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body: JSON.stringify(request)
    }).then(res => res.json())
        .then((result) => {
            return result;
        },
            (error) => {
                return error;
                console.log(error);
            }
            ).catch(error => {
            return error;
            console.log(error.message);
        });
}

export function QntDigitoConta(numBanco) {
    var result = 0;

    switch (numBanco) {
        case "237":
            result = 7
            break;
        case "001":
            result = 14
            break;
        case "033":
            result = 13
            break;
        case "104":
            result = 8
            break;
        case "341":
            result = 8
            break;
    }

    return result
}