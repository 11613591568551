export function GetFaturamento(idMatricula) {

    return fetch("api/Faturamento/" + idMatricula, {
        method: "GET",
        mode: "cors",
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                alert("Erro ao buscar dados de faturamento.");
                console.log(error.message);
                return false;
            }
        )
        .catch(error => {
            alert("Erro ao buscar dados de faturamento.");
            console.log(error.message);
            return false;
        });
}

